import React, { ReactElement } from 'react';
import { ICustomer } from 'shared-types/index';
import PhoneNumberService  from 'shared-services/phone-number-service/index';
import { snakeCase } from 'lodash';

const characters = ['A', 'B', 'C', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N',
  'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'Y', 'W', 'Z',
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'
];

export default class UtilsService {

  static rangeCheck(val: number, max: number, min: number): number {
    if (val < min) {
      val = min;
    } else if(val > max) {
      val = max;
    }
    return val;
  }

  // Maybe useful for future should takeaway mode be enabled on widget v2
  static getOrderType(isTakeAwayMode = false): string {
    return isTakeAwayMode ? 'order' : 'booking';
  }

  // https://stackoverflow.com/questions/5623838/rgb-to-hex-and-hex-to-rgb
  static hexToRgb(hex: string): number[] {
    return hex.replace(/^#?([a-f\d])([a-f\d])([a-f\d])$/i
        ,(m, r, g, b) => '#' + r + r + g + g + b + b)
        .substring(1).match(/.{2}/g)
        .map(x => parseInt(x, 16))
  }

  static getPhoneLinkHtml(phone: string) {
    return `<a class="underline-phone" href="tel:${phone.split(' ').join('')}">${phone}</a>`;
  }

  /**
   * Make links open in a new window when using ReactMarkdown
   * Use like this:
   * <ReactMarkdown source={text}
   renderers={{
        paragraph: 'span',
        link: UtilsService.reactMarkDownBlankTargets
      }}
   escapeHtml={false} />
   */
  static reactMarkDownBlankTargets(props:{href: string, children: string}): ReactElement {
    return (<a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>);
  }

  /***
   * need call start to start polling, first callback will call immediately after call start();
   * @param callback callback in interval
   * @param interval ms
   * @param count how many times want polling
   */
  static setCustomInterval(callback: any, interval = 1000, count = 100) {
    let timer: NodeJS.Timeout | number;
    let isStop = false
    const stop = () => {
      isStop = true
      clearTimeout(timer as NodeJS.Timeout)
    }
    const start = async () => {
      isStop = false
      await loop()
    }
    const loop: any = async () => {
      try {
        await callback(stop)
      } catch (err) {
        console.error('Polling Error：', err)
        throw new Error('Polling Error：' + err)
      }
      if (!isStop && count > 0) {
        timer = window.setTimeout(loop, interval)
      } else {
        clearTimeout(timer as NodeJS.Timeout);
      }
      count--;
    }
    return {
      start,
      stop
    }
  }

  /**
     * Return a single line value represents the customer main informations
     * @param {Object} customer
     * @param {boolean} showPhone
     * @returns {String} possible formats are:
     * [firstName][ lastName][, company][, phone]
     * [company][, [firstName][ lastName][, phone]
     */
   static getDisplayName(customer: ICustomer, showPhone: boolean) {
    const nameParts = [];
    if (customer.firstName) {
        nameParts.push(customer.firstName);
    }
    if (customer.lastName) {
        nameParts.push(customer.lastName);
    }

    const displayParts = [nameParts.join(' ')];
    if (customer.company) {
        displayParts.push(customer.company);
    }
    if (showPhone && customer.phone) {
        displayParts.push(PhoneNumberService.formatNumber(customer.phone, 'AU', true));
    }

    return displayParts.join(', ');
  }

  static htmlEncode(s: string): string {
    if (!s || typeof (s) !== "string") {
      return s;
    }

    return s.replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
  }

  static htmlDecode(s: string, isNonTextField = false) {
    if (!s || typeof (s) !== "string") {
      return s;
    }
    if (isNonTextField) {
      return s.replace(/&amp;/g, '&')
        .replace(/&#39;/g, "'")
        .replace(/&#34;/g, '"')
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">");
    } else {
      return s.replace(/&amp;/g, '&')
        .replace(/&#39;/g, "'")
        .replace(/&#34;/g, '"');
    }
  }

  static generateRandomCode(sizeOverride = 13): string {
    let code = '';
    while (code.length < sizeOverride) {
      code += characters[Math.floor(Math.random() * Math.floor(characters.length))];
    }
    return code;
  }

  static getResponseItemsCount(response: any): number {
    let quantity = response.headers['content-range'];
    quantity = (quantity && quantity.indexOf('/') >= 0) ? quantity.split('/')[1] : '0';
    return parseInt(quantity, null);
  }

  static changeObjectKeyFromCamelToSnakeCase(obj: {[key: string]: string}): {[key: string]: string} {
    const newObj: {[key: string]: string} = {};
    Object.keys(obj).forEach((key) => {
      newObj[snakeCase(key)] = obj[key];
    });
    return newObj;
  }
}
