/**
 * This component is specific to Widget v2, in that it links to the Preauth and Terms & Conditions pages
 * for this app. However, if ABC also have similar pages, it may need to be reused.
 */

import React from 'react';
import { IProps, IOwnProps, externalLinkType } from './types';
import { makeStyles, createStyles } from '@material-ui/core';
import { IWidgetTheme } from 'shared-types/index';

const NS = 'ExternalLink';

export default function ExternalLink({
  type, accountId, venueId, theme, accent, font, label, bgColor, useDullColor,
  isDark, textColor
}: IProps & IOwnProps) {


  const useStyles = makeStyles((theme: IWidgetTheme) => {
    const dullColor = isDark ? 'rgba(255, 255, 255, 0.7)' : 'rgba(0, 0, 0, 0.54)';

    return createStyles({
      link: {
        color: useDullColor ? dullColor : textColor
      }
    })
  });

  const classes = useStyles({theme});


  const params: any = [
    '?accountid=',
    accountId,
    '&venueid=',
    venueId,
    '&theme=',
    theme,
    '&accent=',
    accent,
    '&font=',
    font
  ];

  if (bgColor) {
    params.push(`&bg=${bgColor.replace('#', '')}`)
  }

  const pageName: string = (type === externalLinkType.preAuth) ? 'preauthorisationpolicy.html' : 'termsandconditions.html';
  const url: string = pageName + params.join('');
  
  return (
    <a href={url} target='_blank' rel='noreferrer noopener' className={classes.link}>
      {label}
    </a>
  )
}

