// @ts-ignore
import * as queryString from 'query-string-es5';

import { IAppQueryStringParams, IAppSettings, modeType } from 'app/models';
import { verifyActionAsEnum } from './route/route.service';
import { LocationService } from './location/location.service';


export class AppLoadService {

  /**
   * Redirects to the Now Book It home page if no `accountid` property
   * exists in the current query string.
   * @returns whether it redirected or not
   */
  static redirectOnNoAccountId(): boolean {

    // Get Params from url
    const params = queryString.parse(location.search) as unknown as IAppQueryStringParams;
    // Redirect to nbi home page if there is no account id rather then displaying an error
    if (!params.accountid &&
        params.mode !== modeType.preview
      ) {
      location.replace('https://www.nowbookit.com/');
      return true;
    }
    return false;
  }


  /**
   * Gets initial `appSettings`. Should be called on app load.
   */
  static getAppSettings(): IAppSettings {
    const params = queryString.parse(location.search) as unknown as IAppQueryStringParams;
    const routeAction: string = location.pathname.replace('/', '');
    const venueId: number = params.venueid ? parseInt(params.venueid, 10) : null;
    return {
        accountId: params.accountid || null,
        venueId: venueId && !isNaN(venueId) ? venueId : null,
        fromURL: (params.fromurl) ? decodeURI(params.fromurl) : undefined,
        startURL: location.href,
        theme: params.theme || null,
        accent: params.accent || params.colors || null,
        font: params.font || null,
        /**
         * 'params.bookingid' can come from diary bookings that have payments, or
         * 'appSettings.bookingId' can get populated when a booking has been retrieved
         */
        bookingId: params.bookingid,
        tokenId: params.token || null,
        date: params.date || null,
        serviceids: params.serviceids || null,
        covers: (params.covers && params.covers !== 'false') ? parseInt(params.covers, 10) : 2,
        action: verifyActionAsEnum(routeAction),
        source: params.source || null,
        debug: params.debug || null,
        mode: params.mode,
        canDeleteBooking: false,
        isStyleGuide: false, // location.pathname === STYLE_GUIDE_URL,
        bgColor: LocationService.getBgColorFromQSParam(params.bg), // could be 'transparent' value or any hex color
        banner: params.banner,
        time: params.time || null
    };
  }
}
// creates a Singleton class
export const appLoadService = new AppLoadService();
