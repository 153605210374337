// @ts-ignore
import * as queryString from 'query-string-es5';
import { IAppQueryStringParams, IVenue, modeType, IWidgetModel, IAppSettings, IVenueMinimal } from "app/models";
import { Dispatch } from "redux";
import moment, {Moment} from "moment-timezone";
import { first, switchMap } from 'rxjs/operators';
import { IActionGen, loadStatus, IAction, loaderType} from 'app/types/common.types';
import PhoneNumberService from 'shared-services/phone-number-service/index';
import { IBooking, IBookingResponseData, IPrivateFunction } from 'app/services/booking/booking.types';
import { MessageService } from 'app/services/message/message.service';
import { loadAccountInfo, loadFont, setupBookingPayment } from './helpers';
import { IAppLoadedPayload, IVenueLoadedPayload, IAppLoadedStatus } from './interfaces';
import { ISetup } from 'app/reducers/state';
import { RouteService } from 'app/services/route/route.service';
import { BookingService } from 'app/services/booking/booking.service';
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';
import { ROUTE_NAMES } from 'app/services/route/route.types';
import { IPaymentType } from 'app/services/client/client.types';
import { PaymentService } from 'app/services/payment/payment.service';
import { IResponse } from 'app/containers/App/types.d';
import { IManageBookingFail } from 'app/actions/booking/interfaces';
import DateUtilsService from 'shared-services/date-utils-service';
import { SessionService } from '../../services/session/session.service';
import { sendSessionTimeoutAnalytics } from '../booking/analyticsHelpers';
import { ISessionTime } from '../../services/session/session.types';
import {IErrorResponse, bookingErrorType} from "shared-types/WidgetTypes";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {servicePaymentType} from "shared-types/index";
import { t } from 'i18next';

const NS = 'SetupActionsNS';

export namespace SetupActionsNS {

  /**
   * action types
   */
  export enum Type {
    APP_LOAD_PROGRESS = 'APP_LOAD_PROGRESS',
    APP_LOAD_SUCCESS = 'APP_LOAD_SUCCESS',
    VENUE_LOAD_SUCCESS = 'VENUE_LOAD_SUCCESS',
    VENUE_READY = 'VENUE_READY',
    APP_LOAD_COMPLETE = 'APP_LOAD_COMPLETE',
    LIB_PHONE_LOAD = 'LIB_PHONE_LOAD',
    LOADED_PRIVATE_FUNCTION_PAYMENT_SUCCESS = 'LOADED_PRIVATE_FUNCTION_PAYMENT_SUCCESS',
    LOADED_BOOKING = 'LOADED_BOOKING',
    SET_APP_THEME = 'SET_APP_THEME',
    STANDBY_HAD_PAYMENT = 'STANDBY_HAD_PAYMENT',
    STANDBY_EXPIRED = 'STANDBY_EXPIRED'
  }


  export interface IAppLoadedResolved {
    appSettings: IAppSettings;
    booking: IBooking;
    activeVenue: IVenueMinimal;
  }

  export interface IAppLoading {
    appLoadMessage: string;
    appLoaderType: loaderType;
  }

  export interface IAppLoadComplete extends IAction {
    payload?: IAppLoadedStatus;
  }

  export interface IBookingLoadedSuccess extends IAction {
    payload?: IBookingResponseData;
  }

  export interface ILoadPrivateFunctionSuccess extends IAction {
    payload?: IPrivateFunction;
  }

  export interface ILibPhoneStatus extends IAction {
    payload?: loadStatus;
  }


  /**
   * thunk action creators
   */

  export const appLoadFailed = (msg?: string) => (dispatch: Dispatch) => {
    return new Promise((resolve) => {
      dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
        completeLoadStatus: true,
        status: loadStatus.failed,
        msg
      }} as SetupActionsNS.IAppLoadComplete);
      resolve(null);
    })
  }




  export const appLoaded = () => (dispatch: Dispatch, getState: any): Promise<IAppLoadedResolved> => {
    return new Promise((resolve, reject) => {

      const queryStringParams = queryString.parse(location.search) as unknown as IAppQueryStringParams;
      let fontStatus: string = loadStatus.loading;
      let accountStatus: string = loadStatus.loading;
      let errorMsg: string;
      const {appSettings}: IWidgetModel = getState().widget;
      const isStyleGuide: boolean = appSettings.isStyleGuide;
      const isPreviewMode: boolean = queryStringParams.mode === modeType.preview;

      dispatch({type: Type.APP_LOAD_PROGRESS, payload: {
        appLoadMessage: MessageService.getLoadingMessage(queryStringParams)
      } as IAppLoading});

      const checkComplete = (completeLoadStatus: boolean, msg?: string) => {

        if (msg) {
          errorMsg = msg;
        }
        if (accountStatus !== loadStatus.loading && fontStatus !== loadStatus.loading) {


          dispatch({type: SetupActionsNS.Type.SET_APP_THEME, payload: queryStringParams} as IActionGen<IAppQueryStringParams>);

          if (accountStatus === loadStatus.failed) {
            console.log(NS, 'errorMsg', errorMsg);
            reject(errorMsg);
          } else {
            // even if `fontStatus` is `failed`, we still let the app continue to run and log `errorMsg` for debugging
            if (errorMsg) {
              console.warn(errorMsg);
            }

            dispatch({type: SetupActionsNS.Type.APP_LOAD_SUCCESS, payload: {
              appSettings: getState().widget.appSettings, completeLoadStatus, overrideStyleGuide: false
            } as IAppLoadedPayload});

            dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
              completeLoadStatus,
              status: loadStatus.success
            }} as IAppLoadComplete);


            // at this point need to getState again because booking info may have been updated
            const {booking, activeVenue}: IWidgetModel = getState().widget;

            /**
             * NBI-8205: if using the venue selector (AKA Group Widget - not ABC), the venueId will not get set yet
             * and VENUE_READY will get called when a venue is selected by the user.
             */
            if ((activeVenue as IVenue)?.id) {
              dispatch({type: SetupActionsNS.Type.VENUE_READY, payload: null});
            }

            resolve({
              appSettings,
              booking,
              activeVenue
            });
          }
        }
      };

      loadFont(queryStringParams, dispatch)
        .subscribe(({msg, status}) => {
          fontStatus = status;
          checkComplete(true, msg);
        });

      /**
       * preview (used in admin) doesn't need account details
       */
      if (isStyleGuide || isPreviewMode) {
        dispatch({type: Type.VENUE_LOAD_SUCCESS, payload: {
          queryStringParams,
          appSettings,
          accountDetails: null // preview doesn't need account details
        } as IVenueLoadedPayload});

        // don't need to pass a value, since `checkRouteRules` will direct it to `sitting` route
        RouteService.routeTo(null, dispatch, getState().widget.appSettings, null).then(() => {
          accountStatus = loadStatus.loaded;
          checkComplete(true);
        });
        // if set to preview mode, then no account info is needed
        return
      }


      loadAccountInfo(queryStringParams, appSettings, dispatch, getState)
        .pipe(first())
        .subscribe(({status, msg, completeLoadStatus}) => {
          accountStatus = status;
          checkComplete(completeLoadStatus, msg);
        }, (rawResp: any) => {
          const response: IErrorResponse = rawResp.response as IErrorResponse;
          accountStatus = loadStatus.failed;

          let errMsg;
          if (rawResp.message === 'Network Error' || response.status === 404 || response.status === 504 || response.status === 502) {
              errMsg = t('System is experiencing some latency and connectivity issues. <br/>Some services will be temporarily unavailable.', {
                ns: "errors"
              });
          } else {
            errMsg = response.data?.message || ''
          }

          checkComplete(true, errMsg);
        })
    });
  };

  /**
   * Used for displaying payment gateway when standby booking has a payment on it
   **/
  export const openPayments = () => (dispatch: Dispatch, getState: () => any): void => {
    const {appSettings, booking, activeVenue}: IWidgetModel = getState().widget;
    const _activeVenue: IVenue = activeVenue as IVenue;
    const venueId = _activeVenue.id;

    let bookingResponseData: IBookingResponseData;

    const stopLoader = () => {
      dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
          completeLoadStatus: true,
          status: loadStatus.success // we still say success
      }} as SetupActionsNS.IAppLoadComplete);
    };

    const expiryTime: Moment = DateUtilsService.getDateWithOffset(booking.standByConfirmationExpiry, true) as Moment;
    const currentTime: Date = DateUtilsService.getCurrentTimeByTimeZone(_activeVenue.timeZoneId) as Date;

    if (currentTime > expiryTime.toDate()) {
      dispatch({type: BookingActionsTypes.EDIT_BOOKING_FAIL, payload: {
        title: 'Problem opening payment page',
        message: "Standby expired at {{time}}",
        params: {time: expiryTime.format('h:mm:ss a (YYYY-MM-DD)'), ns: "errors"}
      }} as IManageBookingFail);
      return;
    }

    // calls back end to set paymentPending on booking, which is needed before a payment can be added
    setupBookingPayment(booking._id, venueId, dispatch, getState, (data) => {
      bookingResponseData = data;
    })
    .pipe(
       first(),
       // then starts the payment timer on the back end, so standby expiry countdown doesn't interfere with making a payment
       switchMap(() => PaymentService.beginStandbyPayment(booking._id, venueId)),

      // just for debugging error in response
       // map(() => { throw new Error("testing problem") }),
    )
    .subscribe((response: IResponse<string>) => {
      startPaymentSessionCountDown(dispatch, _activeVenue, appSettings);
      routeToPaymentPage(bookingResponseData, dispatch, _activeVenue, appSettings);
      stopLoader();
    }, err => {
      console.warn(NS, 'Error opening payments', err);
      stopLoader();
      dispatch({type: BookingActionsTypes.EDIT_BOOKING_FAIL, payload: {
        title: 'Problem opening payment page',
        message: 'Sorry, there was a problem with the payment setup on this booking. Please contact us on <a class="underline-phone" href="tel:{{phone}}">{{phone}}</a>.'
      }} as IManageBookingFail);
    });
  }


  function routeToPaymentPage(bookingResponseData: IBookingResponseData, dispatch: Dispatch, activeVenue: IVenue, appSettings: IAppSettings): void {
    // needs to convert using getBookingObj because of pre-auth conditions
    const {payment} = BookingService.getBookingObj(bookingResponseData);

    if (payment && payment.paymentType !== servicePaymentType.noPayment && payment.price) {
      dispatch({type: NavigationActionsNS.Type.UPDATED_BOOKING_PAYMENT_DETAILS, payload: {
        paymentTypeName: payment.paymentType, amount: payment.price, amountDue: payment.amountDue, discountAmount: payment.discountAmount
      }} as IActionGen<IPaymentType>);

      RouteService.routeTo(ROUTE_NAMES.PAYMENTS, dispatch, appSettings, activeVenue);
    } else {
      // shouldn't be possible, but catching it just in case price somehow gets removed
      RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue)
        .then(() => {
          dispatch({type: SetupActionsNS.Type.STANDBY_HAD_PAYMENT});
        });
    }
  }

  /**
   * Starts countdown for the payment page session
   */
  function startPaymentSessionCountDown(dispatch: Dispatch, activeVenue: IVenue, appSettings: IAppSettings): void {
     // long running subscription
    SessionService.startSession()
      .subscribe(({isActive, viewTime}: ISessionTime) => {
        if (viewTime) {
          dispatch({type: BookingActionsTypes.SESSION_REMAINING_TIME_UPDATED, payload: {isActive, viewTime}} as IActionGen<ISessionTime>);
        } else {
          sendSessionTimeoutAnalytics(activeVenue);
          RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, activeVenue).then(() => {
            // will be null if there is no error (eg expired)
            dispatch({type: BookingActionsTypes.SESSION_FINISHED, payload: bookingErrorType.bookingExpiredBeforePayment} as IActionGen<bookingErrorType>);
          });
        }
      });
  }

}
