import {
  IProcessEwayPaymentResponse, IPaymentSummaryResponse, IFunctionPaymentSummaryResponse,
  IPaymentSummaryResponseData, IStripePaymentSuccessData, IPromoCodeResponseData
} from "app/services/payment/payment.types";
import { Action } from "redux-actions";
import { IAction } from "app/types/common.types";
import { ICustomer, IBookingMenuOption, IBookingResponseData } from "app/services/booking/booking.types";
import { IManageBookingError } from "app/models";
import {
  IPaymentTypeOverride,
  ISimpleBookingOption,
  IBookingTag,
  ISavePreAuthResponseData,
  IStripePaymentError,
  IErrorResponse,
  bookingErrorType,
  IEwaySummaryResponseData
} from "shared-types/index";

export interface IEwayPaymentSummary {
  successData?: IFunctionPaymentSummaryResponse | IProcessEwayPaymentResponse | IPaymentSummaryResponse;
  errorDispatchData?: Action<any>;
}


// action creator interfaces
export interface IChangedBookingTime extends IAction {
  payload?: string;
}
export interface IChangedCalenderView extends IAction {
  payload?: boolean;
}
export interface IChangedUpsellPresent extends IAction {
  payload?: boolean;
}
export interface IChangedCustomerDetails extends IAction {
  payload?: {customerDetails: ICustomer, tags: IBookingTag[], isValid: boolean, country: string, phoneWithoutPrefix: string};
}
export interface ISelectedMenuOption extends IAction {
  payload?: IBookingMenuOption[];
}

export interface IUpdateCachedChildMenuOptionDetails extends IAction {
  payload?: {
    options: ISimpleBookingOption[];
    fullRefresh: boolean;
  };
}
export interface IConfirmBookingSuccess extends IAction {
  payload?: IBookingResponseData;
}
export interface ICancelBookingFail extends IAction {
  payload?: bookingErrorType;
}
export interface IPromoCodeFail extends IAction {
  payload?: {
    // should provide one or the other
    errorType?: bookingErrorType;
    message?: string;
  };
}
export interface IManageBookingFail extends IAction {
  payload?: IManageBookingError;
}
export interface IDeleteBookingSuccess extends IAction {
  payload?: any;
}
export interface IDeleteBookingFail extends IAction {
  payload?: IErrorResponse;
}
export interface IPrepareEwayPaymentSuccess extends IAction {
  payload?: void;
}
export interface IPrepareEwayPaymentFail extends IAction {
  payload?: IErrorResponse;
}
export interface IEwayPaymentSuccess extends IAction {
  payload?: IEwaySummaryResponseData;
}
export interface IBookingErrorAction extends IAction {
  payload?: bookingErrorType;
}
export interface IEwayPreAuthSuccess extends IAction {
  payload?: ISavePreAuthResponseData;
}
export interface IStripeLoaded extends IAction {
  payload?: stripe.Stripe;
}
export interface IStripePaymentSuccess extends IAction {
  payload?: IStripePaymentSuccessData;
}
export interface IStripePaymentFail extends IAction {
  payload?: IStripePaymentError;
}
export interface IPaymentSummaryFail extends IAction {
  payload?: IErrorResponse;
}
export interface IPromoCodeVerified extends IAction {
  payload?: IPromoCodeResponseData;
}

export interface ICoversUpdate {
  covers: number;
  clearSelectionMenuOptions: boolean;
}

export interface IChangedActiveServiceUpdate {
  serviceId: string;
  clearSelectionMenuOptions: boolean;
}

export interface IProcessStripeFullPayment {
  errorPayload?: IStripePaymentError;
  successPayload?: IStripePaymentSuccessData;
}

export interface IProcessStripePreauthPayment {
  errorStripePayload?: IStripePaymentError;
  errorPreauthPayload?: bookingErrorType;
  successPayload?: ISavePreAuthResponseData;
}


export interface ISavePreAuth {
  errorPayload?: bookingErrorType;
  successPayload?: ISavePreAuthResponseData;
}

export interface IApplyPromoCode {
  codeFailMsg: string;
  payment: IPromoCodeResponseData;
}

export interface IUpdateActiveChildMenuOptionPayload {
  implicitChildMenuOptions: ISimpleBookingOption[];
  explicitChildMenuOptions: ISimpleBookingOption[];
}

export interface IUpdateBookingOptionPaymentType {
  paymentTypeOverride?: IPaymentTypeOverride;
  id: string;
}

export enum GTMFields {
  Email = 'Email',
  Phone = 'Phone',
  FirstName = 'FirstName',
  LastName = 'LastName',
}
