import moment, {Moment} from "moment-timezone";
import { cloneDeep } from 'lodash-es';

const NS = 'DateUtilsService';

export default class DateUtilsService {

  /**
   * Gets time in minutes between 2 date times
   * @param earlierTime - often this refers to the first time in the first service of a schedule
   * @param laterTime - often this refers to the first time in a later service
   * @return difference between the two in minutes
   */
  static dateTimeNum(earlierTime: Date, laterTime: Date): number {
    const earlierTimeInMins = ((earlierTime.getTime() / 1000) / 60);
    const laterTimeInMins = ((laterTime.getTime() / 1000) / 60);
    return laterTimeInMins - earlierTimeInMins;
  }

  /**
   * Checks if a specified time is more than or equal to a start time and less than an end time
   * @param date - time to check
   * @param startDate - start time
   * @param endDate - end time
   * @return true if within, false otherwise
   */
  static isInBetweenDates(date: Date, startDate: Date, endDate: Date): boolean {
      return (date < endDate && date >= startDate);
  }

  /**
   * Converts a date time to a specified format, defaulting to 2 digit hour and 2 digit minutes,
   * plus lowercase am/pm suffix
   * @param date - date object
   * @param format - optional format
   * @return formatted string time
   */
  static dateToTimeStr(date: Date, format?: string): string  {
      const momentDate = moment(date);
      return momentDate.format(format || 'hh:mm a');
  }
  static dateToTimeStr12Hour(date: Date): string {
    return this.dateToTimeStr(date, 'h:mm A');
  }
  /**
   * converts date-time object to 2050/02/01 14:30 (24 hour) format
   * @param date - date object
   * @return string time in 2050/02/01 14:30 format
   */
  static dateToDateTimeStr(date: Date) {
    const momentDate = moment(date);
    return momentDate.format('YYYY/MM/DD HH:mm');
  }

  /**
   * Converts date-time string (eg "2019-11-14T06:00:00") to a date object
   */
  static dateStrToDateTime(dateStr: string): Date {
      const momentDate = moment(dateStr);
      return momentDate.toDate();
  }

  /**
   * Converts date-time string (eg "2019-11-14T06:00:00") to a date object
   */
  static DateToDateTimeString(date: Date): string {
    const momentDate = moment(date);
    return momentDate.format('YYYY-MM-DDTHH:mm:ss');
  }

  /**
   * Converts date string without time info (eg "2019-11-14") to a date object
   * @param dateStr - string in format YYYY-MM-DD with optional separator character
   * @param join - optional separator character
   */
  static dateStrToDate(dateStr: string, join = '-') {
      const dateParts: string[] = dateStr.split(join);
      const year = Number(dateParts[0]);
      const month = Number(dateParts[1]) - 1;
      const day = Number(dateParts[2]);

      return new Date(year, month, day);
  }

    /**
   * Converts date string without time info (eg "2019/11/14 13:12:11") to a date object
   * @param dateStr - string in format YYYY-MM-DD with optional separator character
   * @param join - optional separator character
   */
     static dateTimeStrToDate(dateStr: string) {
      // allow the time as string transform
      const parts = dateStr.split(' ');
      const dateParts = parts[0].split('/');
      const hourParts = parts[1].split(':');

      return new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]), Number(hourParts[0]), Number(hourParts[1]));
    }

  /**
   * Gets the current 24 hour time as a string at venue
   * @param timeZone - eg "Australia/Perth"
   * @param dateStr - optional date field, useful for testing
   */
  static getCorrectTime(timeZone: string, dateStr?: string): string {
    const browserTime: string = moment(dateStr).format();
    return moment.tz(browserTime, timeZone).format('YYYY-MM-DD H:mm:ss');
  }

  /**
   * Converts date-time string (eg "2019-11-14T06:00:00") to a date object.
   * Same as dateStrToDateTime, but doesn't rely on moment.js
   */
  static getJsDate = function (date: string): Date {
      const a: string[] = date.split(/[^0-9]/);
      const year: number = parseInt(a[0], 10);
      const month: number = parseInt(a[1], 10);
      const dayDate: number = parseInt(a[2], 10);
      const hours: number = parseInt(a[3], 10);
      const mins: number = parseInt(a[4], 10);
      const secs: number = parseInt(a[5], 10);

      return new Date(year, month - 1, dayDate, hours, mins, secs);
  }


  /**
   * Takes a moment object and timezone id and converts the moment to be relative to the venue's timezone.
   * If you were to call `.toDate()` after this you should get your local time with the offset added to it.
   * For example:
   *  - a booking in Perth at 'Thu Sep 03 2020 12:23:11'
   *  - would look like this for a Sydney user 'Thu Sep 03 2020 14:23:11 GMT+1000 (Australian Eastern Standard Time)'
   *  which has a 2 hour offset added to it
   * @param dateTime: eg a maxDate or a booking time
   * @param timeZone: eg 'Australia/Perth'
   * @param dateStr - optional date field, useful for testing
   */
  static convertMomentToVenueDateTime(dateTime: Moment, timeZone: string, dateStr?: string): void {
    dateTime.tz(timeZone);
    const userTZOffset = moment(dateStr).utcOffset();
    const venueTZOffset = dateTime.utcOffset();
    const offsetInMins = userTZOffset - venueTZOffset;

    dateTime.add(offsetInMins, "minutes");
  }

  /**
   * returns a booking's end time as a date object, using the start time and duration
   */
  static getBookingEnd(b: {time: Date, duration: number}): Date {
      const bookingEnd = moment(b.time).add(b.duration, 'minutes');
      return bookingEnd.toDate();
  }

  static getViewDateFromMoment(moment: Moment): string {
      return moment.format('dddd, MMMM D, YYYY');
  }

  static reformatDateFromDate(date: Date): Date {
    return moment(date).toDate()
  }
  /**
   * change date to another timezone, but time not change
   * @param date the date want change
   * @param timeZoneId the timezone you want go
   */
  static onlyChangeTimeZone(date: Date, timeZoneId: string): Date {
    if (!date || !timeZoneId) {
      return date;
    }
    return new Date(moment(date).parseZone().tz(timeZoneId, true).toISOString())
  }
  // difference between toVenueTime and onlyChangeTimeZone
  // Brisbane is +8, Sydney is +10 for example
  // toVenueTime: 2020-09-03T12:00:00+08:00 -> 2020-09-03T14:00:00+10:00
  // onlyChangeTimeZone: 2020-09-03T12:00:00+08:00 -> 2020-09-03T12:00:00+10:00(which is 2020-09-03T10:00:00+08:00)
  // if we pass result to same function again, toVenueTime will return 2020-09-03T16:00:00+10:00
  // onlyChangeTimeZone will return 2020-09-03T10:00:00+10:00(which is 2020-09-03T08:00:00+08:00)
  // if not familiar about time format.
  // so onlyChangeTimeZone is keep time, but change timezone. Brisbane 10:00 -> Sydney 10:00
  // if we input again, Sydney 10:00 is Brisbane 8:00, so we get Brisbane 8:00 -> Sydney 8:00
  // toVenueTime. Brisbane 10:00 -> Sydney 12:00
  // but if you input again. it won't consider timezone, so we get Sydney 12:00(but it will treat as Brisbane 12:00) -> Sydney 14:00
  // so onlyChangeTimeZone is keep decrease 2 hours, toVenueTime is keep increase 2 hours
  // if still not clear, check commented out code in test file -- describe('toVenueTime',

  // @todo: needs unit tests
  static toVenueTime(date: string | Date, timeZoneId: string): Date {
    if (typeof date === 'string') {
      date = date.split('/').join('-').split(' ').join('T');
    }
    if (!timeZoneId) {
      return new Date(date);
    }
    const momentDate = moment(date).tz(timeZoneId);
    const venueDate = new Date(momentDate.format('YYYY-MM-DD'));
    venueDate.setHours(parseInt(momentDate.format('H')));
    venueDate.setMinutes(parseInt(momentDate.format('m')));
    venueDate.setSeconds(parseInt(momentDate.format('s')));
    return venueDate;
  }

  static getVenueTime(timeZoneId: string): Date {
    const today = moment();
    // convert today's date into the venues timezone
    if (timeZoneId) {
      const year = Number(today.tz(timeZoneId).format('YYYY'));
      const month = Number(parseInt(today.tz(timeZoneId).format('M')) - 1);
      const day = Number(today.tz(timeZoneId).format('D'));
      const hours = Number(today.tz(timeZoneId).format('H'));
      const min = Number(today.tz(timeZoneId).format('m'));
      const sec = Number(today.tz(timeZoneId).format('s'));
      const venueDate = new Date(year, month, day, hours, min, sec);
      return venueDate;
    }
    return new Date();
  }

  static getClearDate(date: Date, timeZoneId: string): Date {
    if (!date) {
      date = this.getVenueTime(timeZoneId);
    }
    date = cloneDeep(date);
    date.setHours(0);
    date.setMinutes(0);
    date.setSeconds(0);
    date.setMilliseconds(0);
    return date;
  }

  static isDateToday(date: Date, timeZoneId: string): boolean {
    const today = this.getVenueTime(timeZoneId);
    return this.isOnSameDate(date, today);
  }

  /**
   * Convert time string to a Date
   */
  static timeToDate(timeStr: string | Date, fullDate: Date): Date {
    if (timeStr instanceof Date) {
      return timeStr;
    }
    if (!fullDate) {
      console.error('timeToDate fullDate input undefined, timeStr', timeStr);
      return null;
    }
    if (!timeStr) {
      console.error('timeToDate timeStr input undefined, fullDate', fullDate);
      return null;
    }
    if (timeStr.indexOf(':') === -1) {
      console.error('timeToDate timeStr input format', timeStr, fullDate);
      return null;
    }
    const timeParts = timeStr.split(':');
    let hour = Number(timeParts[0]);
    const min = Number(timeParts[1]);
    const date = new Date(fullDate.getTime());
    hour = (hour === 24) ? 0 : hour;
    date.setHours(hour);
    date.setMinutes(min);
    date.setSeconds(0);
    date.setMilliseconds(0);
    // if the time is before 4am amke it the next day
    // our day runs from 4am - 4am
    //TODO add this to config
    if (date.getHours() < 4) {
      date.setDate(date.getDate() + 1);
    }
    return date;
  }

  static checkDateDay(date: Date, orgDate: Date): Date {
    if (date && orgDate) {
      // make sure the date is the original date
      date.setDate(orgDate.getDate());
      date.setMonth(orgDate.getMonth());
      date.setFullYear(orgDate.getFullYear());
      // if the time is before 4am make it the next day
      // our day runs from 4am - 4am
      //TODO add this to config
      if (date.getHours() < 4) {
        date.setDate(orgDate.getDate() + 1);
      } else {
        date.setDate(orgDate.getDate());
      }
    }
    return date;
  }

  static closeTimesToDate(timeStr: string, openDate: Date): Date {
    const closeDate = this.timeToDate(timeStr, openDate);
    if (closeDate && closeDate.getTime() < openDate.getTime()) {
      // the close time is before the open time make it occour on the next day
      closeDate.setDate(closeDate.getDate() + 1);
    } else if (closeDate && closeDate.getDate() !== openDate.getDate()) {
      closeDate.setDate(openDate.getDate());
      closeDate.setMonth(openDate.getMonth());
    }
    return closeDate;
  }

  static isOnOrInBetweenDates(date: Date, startDate: Date, endDate: Date): boolean {
    return (date <= endDate && date >= startDate);
  }

  static daysBetweenDates(startDate: Date, endDate: Date): number {
    return Math.abs(startDate.valueOf() - endDate.valueOf()) / (1000 * 60 * 60 * 24);
  }

  static hoursBetweenDates(startDate: Date, endDate: Date): number {
    let diff = 0;
    if (startDate && endDate) {
      const startOffset = startDate.getTimezoneOffset();
      const endOffset = endDate.getTimezoneOffset();
      diff = startOffset - endOffset;
    }
    let hours = Math.abs(startDate.valueOf() - endDate.valueOf()) / (60 * 60 * 1000);
    if (diff !== 0) {
      diff = diff / 60;
      hours = hours + diff;
    }
    return hours;
  }

  static minsBetweenDates(startDate: Date, endDate: Date): number {
    let diff = 0;
    if (startDate && endDate) {
      const startOffset = startDate.getTimezoneOffset();
      const endOffset = endDate.getTimezoneOffset();
      diff = startOffset - endOffset;
    }
    let mins = Math.abs(startDate.valueOf() - endDate.valueOf()) / (60 * 1000);
    if (diff !== 0) {
      mins = mins + diff;
    }
    return mins;
  }

  static addMinutesToTime(date: Date, minutes: number): Date {
    return new Date(date.setMinutes(date.getMinutes() + minutes));
  }

  static getMinOfDay(date: Date, time: Date | string): number {
    if (!(time instanceof Date)) {
      time = this.dateTimeStrToDate(time);
    }
    return this.minsBetweenDates(date, time);
  }

  static isOnSameDate(date1: Date, date2: Date): boolean {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      console.error('isOnSameDate invalid inputs', date1, date2);
      return false;
    }

    return date1.getDate() === date2.getDate() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getFullYear() === date2.getFullYear();
  }

  /**
   * Returns a new moment date from the start of the day, using the date provided
   * @param dateTime {Date} - any date time
   * @param returnAsDate {Boolean} - if true, returns as Date, otherwise Moment
   * @returns {Moment} - moment from the beginning of the provided day
   */
  static getStartOfDay(dateTime: Date, returnAsDate = false): Date | moment.Moment {
    const m = moment(dateTime).startOf('day');
    return returnAsDate ? m.toDate() : m;
  }

  /**
   *
   * @param standByConfirmationExpiry {string} - date that standby request expires
   * @returns {boolean}
   */
  static checkIfStandbyConfirmationExpired(standByConfirmationExpiry: string): boolean {

    if (!standByConfirmationExpiry) {
      return false;
    }

    const defaultDate = '0001/01/01 00:00';
    if (standByConfirmationExpiry !== defaultDate) {
      const extendedTime = moment.utc(standByConfirmationExpiry).local();
      const currentTime = moment();
      const timeDifference = extendedTime.diff(currentTime, 'minutes');
      return timeDifference < 0;
    }

    return false;
  }

  static setTimeFromString(date: Date, time: string): Date {
    // get the hours and minutes as numbers
    const h = parseInt(time.split(':')[0], 10);
    const m = parseInt(time.split(':')[1], 10);
    // then adds it to existing date object
    const existingDate = moment(date);
    existingDate.set({h, m});

    return existingDate.toDate();
  }

  static setFutureDateInISO(date: Date, years: number): string {
    const futureDate = cloneDeep(date);
    futureDate.setFullYear(date.getFullYear() + years);
    return futureDate.toISOString().split('.')[0];
  }

  static dateToIdStr(date: Date | number): string { // date could be Date or number
    return moment(date).format('YYYY-MM-DD');
  }
  // widget
  static getCurrentTimeByTimeZone(timeZoneId: string, returnAsMoment = false): Date | Moment {
    const mom: Moment = moment.tz(moment(), timeZoneId);

    if (returnAsMoment) return mom;

    return mom.toDate();
  }

  /**
   * Adds the UTC offset to a Date object to get the time as local
   */
  // widget
  static getDateWithOffset(date?: Date, returnAsMoment = false): Date | Moment {
    const newMoment = date ? moment(date) : moment();
    newMoment.add(newMoment.utcOffset(), "minutes");

    if (returnAsMoment) return newMoment;
    return newMoment.toDate();
  }

  // widget
  static checkForLastDate(date: Moment): boolean {
    const tomorrow = moment().add(1,'days');
    return tomorrow.isSameOrAfter(date, 'day');
  }

  static getSanitizedDate(dateStr: Date | string): Date {
    if ((dateStr) instanceof Date) {
      return dateStr;
    }

    return new Date(dateStr);
  }
}
