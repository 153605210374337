import {
  IBookingError,
  IErrorResponseData,
  IEwayErrorCode
} from './error.types';
import DEFAULT_MESSAGES from '../../constants/defaultMessages';
import {IVenue} from 'app/models';
import {MessageService} from '../message/message.service';
import {bookingErrorMessageType, bookingErrorType, servicePaymentType} from "shared-types/index";
import i18next, { t } from 'i18next';

const NS = 'ErrorService';
const TRY_AGAIN = 'Try Again';

export class ErrorService {

  static getBookingErrors(): IBookingError[] {
    // Use the global `t` function. This is not reactive, but because these
    // messages have interpolated data (`{{phone}}`) via MessageService, we
    // must use a different mechanism to localize on language change.
    const t = i18next.t;

    return [{
      name: bookingErrorType.serverDown,
      heading: '',
      messageType: bookingErrorMessageType.serverError,
      data: '',
      status: 400,
      message: 'We are currently experiencing technical difficulties, please try again later.',
      buttonText: ''
    }, {
      name: bookingErrorType.noAccount,
      heading: 'Online Bookings Unavailable',
      messageType: bookingErrorMessageType.noAccount,
      data: '',
      status: 400,
      message: 'We are sorry an error occurred while trying to access this account.',
      buttonText: ''
    }, {
      name: bookingErrorType.pageError,
      heading: 'Error',
      messageType: bookingErrorMessageType.serverError,
      data: '',
      status: 400,
      message: 'Sorry, an error has occurred on this page.',
      buttonText: ''
    }, {
      name: bookingErrorType.linkExpired,
      heading: 'Link Expired',
      messageType: bookingErrorMessageType.linkExpired,
      data: '',
      status: 400,
      message: 'Sorry, this link has expired.',
      buttonText: ''
    }, {
      name: bookingErrorType.timeout,
      heading: 'Timeout',
      messageType: bookingErrorMessageType.timeout,
      data: '',
      status: 400,
      message: 'Sorry your session has expired.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.paymentTimeout,
      heading: 'Payment Timeout',
      messageType: bookingErrorMessageType.paymentTimeout,
      data: '',
      status: 400,
      message: 'Sorry, the request has timed out.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.clientError,
      heading: 'Booking Failed',
      messageType: bookingErrorMessageType.clientError,
      data: '',
      status: 400,
      message: 'Sorry your booking has failed.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.duplicate,
      heading: 'Booking Taken',
      messageType: bookingErrorMessageType.noTables,
      data: '',
      status: 400,
      message: 'Sorry your booking has been taken.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.conflict,
      heading: 'Time No longer Available',
      messageType: bookingErrorMessageType.timeNoLongerAvailableMessage,
      data: '',
      status: 409,
      message: DEFAULT_MESSAGES.timeNoLongerAvailableMessage,
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.bookingServerError,
      heading: 'Booking Error',
      messageType: bookingErrorMessageType.bookingError,
      data: '',
      status: 400,
      message: 'We are sorry an error occurred while taking your booking. Please try again or call us on {{phone}}.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.paymentServerError,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.serverError,
      data: '',
      status: 400,
      message: 'We are sorry an error occurred while taking your payment.',
      buttonText: ''
    }, {
      name: bookingErrorType.inactive,
      heading: 'Online Bookings Unavailable',
      messageType: bookingErrorMessageType.inactive,
      data: '',
      status: 400,
      message: DEFAULT_MESSAGES.inactive,
      buttonText: ''
    }, {
      name: bookingErrorType.tooFarInAdvanceMessage,
      heading: 'Online Bookings Unavailable',
      messageType: bookingErrorMessageType.tooFarInAdvanceMessage,
      data: '',
      status: 400,
      message: DEFAULT_MESSAGES.tooFarInAdvanceMessage,
      buttonText: ''
    }, {
      name: bookingErrorType.cancellationFail,
      heading: 'Failed to cancel',
      messageType: bookingErrorMessageType.clientError,
      data: '',
      status: 400,
      message: 'Sorry, we were not able to cancel your booking booking.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.badRequest,
      heading: 'Unable to process your request',
      messageType: bookingErrorMessageType.clientError,
      data: '',
      status: 400,
      message: 'Sorry, we were not able to process this request. Please call us on {{ phone }}',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.bookingExpiredBeforePayment,
      heading: 'Booking Expired',
      messageType: bookingErrorMessageType.clientError,
      data: '',
      status: 400,
      message: 'Sorry, your booking timed out.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.paymentNotSetup,
      heading: 'Unable to take payment',
      messageType: bookingErrorMessageType.clientError,
      data: '',
      status: 400,
      message: 'Sorry, we were not able to process transactions at the moment.  Please call us {{phone}}',
      buttonText: 'Book for another day'
    }, {
      name: bookingErrorType.paymentError,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.paymentError,
      data: '',
      status: 400,
      message: 'We are sorry there was an issue with processing your payment. ',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.paymentAuthError,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.paymentError,
      data: '',
      status: 400,
      message: 'Payment Authentication Error.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.AUTHENTICATION_ERROR,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.paymentError,
      data: '',
      status: 400,
      message: 'We are sorry there was an issue with processing your payment',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.UKNOWN_USERPAYMENTERROR,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.paymentError,
      data: '',
      status: 400,
      message: 'We are sorry there was an issue with processing your request, please call us {{phone}}.',
      buttonText: ''
    }, {
      name: bookingErrorType.STANDBY_HAD_PAYMENT,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.paymentError,
      data: '',
      status: 400,
      message: 'Your standby booking no longer has a payment associated with it. Please refresh the browser.',
      buttonText: ''
    }, {
      name: bookingErrorType.cancellationFail,
      heading: 'Cancellation Fail',
      messageType: bookingErrorMessageType.cancellationFail,
      data: '',
      status: 400,
      message: 'We are sorry there was an issue with canceling your booking, please call us {{phone}}.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.bookingExpired,
      heading: 'Booking Expiry',
      messageType: bookingErrorMessageType.bookingError,
      data: '',
      status: 400,
      message: 'Sorry, your booking timed out. Please try again.',
      buttonText: TRY_AGAIN
    }, {
      name: bookingErrorType.confirmationError,
      heading: 'Confirmation Error',
      messageType: bookingErrorMessageType.confirmationError,
      data: '',
      status: null,
      message: 'Sorry, an error occurred while trying to confirm your booking. Please call us {{phone}}.',
      buttonText: ''
    }, {
      name: bookingErrorType.bookingAlreadyConfirmed,
      heading: 'Booking Confirmed',
      messageType: bookingErrorMessageType.bookingAlreadyConfirmed,
      data: '',
      status: null,
      message: 'Thank you, your booking has been confirmed.',
      buttonText: ''
    }, {
      name: bookingErrorType.cancellationError,
      heading: 'Cancellation Error',
      messageType: bookingErrorMessageType.cancelBookingError,
      data: '',
      status: null,
      message: 'Sorry, an error occurred while trying to cancel your booking. Please call us {{phone}}.',
      buttonText: ''
    }, {
      name: bookingErrorType.bookingAlreadyCancelled,
      heading: 'Booking Cancelled',
      messageType: bookingErrorMessageType.bookingAlreadyCancelled,
      data: '',
      status: null,
      message: 'Thank you, your booking has been cancelled. If this was a mistake, please contact us {{phone}}.',
      buttonText: ''
    }, {
      name: bookingErrorType.bookingCancelled,
      heading: 'Booking Cancelled',
      messageType: bookingErrorMessageType.confirmationError,
      data: '',
      status: null,
      message: 'Your booking has been cancelled. If there has been a mistake, please contact us {{phone}}.',
      buttonText: ''
    }, {
      name: bookingErrorType.duplicateFunctionPayment,
      heading: '',
      messageType: bookingErrorMessageType.functionPayment,
      data: '',
      status: null,
      message: 'This function has already been paid. If you have any questions regarding your booking please contact us {{phone}}.',
      buttonText: ''
    }, {
      name: bookingErrorType.paymentProviderNotSetup,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.paymentProviderNotSetup,
      data: '',
      status: null,
      message: 'A payment provider does not exist for this venue.',
      buttonText: ''
    }, {
      name: bookingErrorType.publishableKeyMissing,
      heading: 'Payment Error',
      messageType: bookingErrorMessageType.publishableKeyMissing,
      data: '',
      status: null,
      message: 'Payment Provider: Publishable key missing.',
      buttonText: ''
    }, {
      name: bookingErrorType.emptyError,
      heading: '',
      messageType: bookingErrorMessageType.emptyError,
      data: '',
      status: null,
      message: null,
      buttonText: ''
    }, {
      name: bookingErrorType.onlineBookingsOffMessage,
      heading: 'Online Bookings Unavailable',
      messageType: bookingErrorMessageType.onlineBookingsOffMessage,
      data: '',
      status: 400,
      message: DEFAULT_MESSAGES.onlineBookingsOffMessage,
      buttonText: ''
    }]
  }

  static getEwayErrorCodes(): IEwayErrorCode[] {
    return [
      {code: "V6011", description: "Invalid payment total amount"},
      {code: "V6015", description: "Invalid Payment CurrencyCode"},
      {code: "V6021", description: "Card holder name required"},
      {code: "V6022", description: "Card number required"},
      {code: "V6153", description: "Card type not support by merchant"},
      {code: "V6101", description: "Invalid credit card expiry month"},
      {code: "V6102", description: "Invalid credit card expiry year"},
      {code: "V6106", description: "Invalid credit card cvn"},
      {code: "V6110", description: "Invalid credit card number"},
      {code: "S9990", description: "Invalid endpoint error"},
      {code: "S9991", description: "Invalid credential error"},
      {code: "S9992", description: "Communication error"},
      {code: "S9993", description: "Authentication error"},
      {code: "S9995", description: "Internal sdk error"},
      {code: "D4406", description: "Unknown error"},
      {code: "D4412", description: "Invalid transaction"},
      {code: "D4413", description: "Invalid amount"},
      {code: "D4414", description: "Invalid card number"},
      {code: "D4433", description: "Expired card, capture"},
      {code: "D4441", description: "Lost card"},
      {code: "D4443", description: "Stolen card"},
      {code: "D4451", description: "Insufficient funds"},
      {code: "D4454", description: "Expired card"},
      {code: "D4462", description: "Restricted card"},
      {code: "D4482", description: "CVV validation error"},
      {code: "D4494", description: "Duplicate transaction"},
      {code: "D4495", description: "Amex declined"},
      {code: "D4496", description: "System error"}
    ];
  }

  static getBookingErrorTypeFromStatus(status: number, paymentType: servicePaymentType): bookingErrorType {
    if (status === 409) {
      return bookingErrorType.conflict;
    } else if (status === 403) {
      return bookingErrorType.duplicate;
    } else if (status >= 500) {
      return bookingErrorType.bookingServerError;
    } else if (status === 400) {
      return paymentType === servicePaymentType.noPayment ? bookingErrorType.badRequest : bookingErrorType.paymentNotSetup;
    } else if (status === 404) {
      return bookingErrorType.badRequest;
    } else {
      return bookingErrorType.UKNOWN_USERPAYMENTERROR;
    }
  }


  static getPaymentErrorTypeFromStatus(status: number): bookingErrorType {
    if (status >= 500) {
      return bookingErrorType.paymentServerError;
    } else if (status === 404) {
      return bookingErrorType.bookingExpired;
    } else if (status >= 400 && status !== 404) {
      return bookingErrorType.paymentError;
    } else {
      return bookingErrorType.UKNOWN_USERPAYMENTERROR;
    }
  }

  static getBookingErrorFromStatus(status: number, venue: IVenue, paymentType?: servicePaymentType): IBookingError {

    const errorName: bookingErrorType = this.getBookingErrorTypeFromStatus(status, paymentType);
    return this.getBookingErrorFromType(errorName, venue);
  }

  static getBookingErrorFromType(errorName: bookingErrorType, venue: IVenue): IBookingError {

    const bookingError: IBookingError = this.getBookingErrors().find(o => o.name === errorName);

    if (bookingError) {
      bookingError.message = MessageService.getMessage(
        t(bookingError.message, { ns: "errors" }),
        venue,
        errorName);
    }
    return bookingError;
  }

  static getPaymentErrorFromResponse(status: number, data: IErrorResponseData, venue: IVenue): IBookingError {
    const errorName: string = this.getPaymentErrorTypeFromStatus(status);
    let message: string;

    if (status >= 400 && status !== 404) {
      message = this.getPaymentErrorMessageFromCode(data);
    }

    const bookingError: IBookingError = this.getBookingErrors().find(o => o.name === errorName);
    if (bookingError) {
      bookingError.message = MessageService.getMessage(
        t(bookingError.message, { ns: "errors" }),
        venue,
        '');
      if (message) {
        bookingError.message += message;
      }
    }
    return bookingError;
  }

  private static getPaymentErrorMessageFromCode(data: IErrorResponseData): string {
    let message = '';

    if (data.message) {
      const _errorCodes: string = data.message;
      const errorCodes: string[] = _errorCodes.split(',');

      // Set message start
      for (const key in errorCodes) {
        let code = errorCodes[key];
        code = code.replace(/\s/g, '');
        if (code === 'V6101') {
          message += 'Invalid credit card expiry month. ';
        } else if (code === 'V6102') {
          message += 'Invalid credit card expiry year. ';
        } else if (code === 'V6106') {
          message += 'Invalid security code. ';
        } else if (code === 'V6110') {
          message += 'Invalid credit card number. ';
        } else if (code === 'D4405') {
          message += 'Generic credit card error. ';
        } else if (code === 'V6153') {
          message += 'Card type not support by merchant';
        }
      }
    }
    return message;
  }

  /**
   * Updates the `paymentError`
   */
  static updatePaymentErrorMessageForEway(code: string): void {

    const message = 'Payment rejected by provider.';
    const errorName = 'paymentError';

    // if (code.indexOf(',') >= 0) {
    //   code = code.split(',');
    // }

    // if (typeof code === 'string') {
    //   message = EWAY_CODES[code];
    // }

    // if (Array.isArray(code)) {
    //   var messages = [];

    //   for (var i = 0; i < code.length; i++) {
    //     var key = code[i];
    //     messages.push(EWAY_CODES[key]);
    //   }

    //   message = messages.join(', ') + '.';
    // }

    // const bookingError: IBookingError = this.getBookingErrors().find(o => o.name === errorName);
    // return bookingError ? bookingError.message : '';
    // vm.bookingErrors[bookingErrorsLookUp.indexOf(errorName)].message = message;
    // $state.go('errorPage');
  }
}
