import { getSupportedEnglishOnly } from "app/constants/supportedLanguages";
import i18next from "i18next";
import { Moment } from "moment";

export class IntlService {
  /**
   * Returns the currency amount, formatted based on the given or current
   * locale. If the value is falsy, returns the empty string.
   * @param val Currency amount to format.
   * @param currency The target currency, e.g. "CAD" or "AUD".
   * @param locale Optionally specify the formatting locale. If omitted, uses
   * the {@link i18next.language} locale, which may be stale if the locale is
   * changed in the UI after the amount is formatted.
   */
  static currencyValue(val: number, currency: string, locale?: string): string {
    if (!val) {
      return "";
    }

    if (
      getSupportedEnglishOnly() &&
      ["AUD", "CAD", "NZD", "SGD", "USD"].includes(currency)
    ) {
      return `$${val.toFixed(2)}`;
    }

    return new Intl.NumberFormat(locale || i18next.language, {
      style: "currency",
      currency,
    }).format(val);
  }

  /**
   * Returns the date formatted according to simplistic locale rules.
   * @param date Date to format, as Momentjs object.
   * @param locale The parameter is not used, but kept for compatibility with {@link currencyValue}
   */
  static formatDate(date: Moment, locale?: string): string {
    return getSupportedEnglishOnly()
      ? date.format("dddd, MMMM D, YYYY")
      : date.format("YYYY-MM-D");
  }
}
