import React, {ReactElement} from 'react';
import {ReactNode} from 'react';
import {debounce} from 'lodash-es';
import {Theme} from "@material-ui/core";
import {createMuiTheme, StylesProvider, ThemeProvider} from "@material-ui/core/styles";
import fromEntries from 'object.fromentries';


export default class ReactUtilsService {
    /**
     * Runs through each of `propNames` on object and assigns an empty object if `undefined`.
     * @param obj - any object
     * @param propNames - array of property names in order
     */
    static fillWithEmpty(obj: any, propNames: string[]): void {
        const prop = propNames[0];
        if (prop && obj[prop] === undefined) {
            obj[prop] = {};
        }
        propNames.shift();
        if (propNames.length) {
            this.fillWithEmpty(obj[prop], propNames);
        }
    }

    /**
     * Helper to avoid synthetic event null issue on react handlers when using debounce.
     * Warning: `currentTarget` may be null and target's value (if input) may not update correctly.
     * REF: https://stackoverflow.com/questions/35435074/using-debouncer-with-react-event
     * @param fn
     * @param time
     */
    static debounceEventHandler(fn: (e: any) => void, time: number) {
        const debounced = debounce(fn, time);
        return (evt: any) => {
            evt.persist();
            return debounced(evt);
        }
    }

    /**
     * Renders a ReactNode based on a truthy condition. Else can be optionally provided either as a ReactNode or a function returning a ReactNode.
     * If you are using `elseContent` it is recommended that you wrap you're ReactNodes in functions to avoid them getting executed even when conditions are not met.
     * For example, if `elseContent` is provided, and you have functions within it that will error without some specific data, you should wrap the ReactNode in a function,
     * so it only gets called when the condition becomes falsy. Otherwise you'll get errors even though the condition is still true, as both ReactNodes will get executed,
     * even though they are not both rendered.
     * @param condition
     * @param content
     * @param elseContent
     */
    static renderIf(condition: any, content: ReactNode | (() => ReactNode), elseContent: ReactNode | (() => ReactNode) = null): ReactNode {
        return condition ? (
            typeof content === 'function' ? content() : content
        ) : (
            typeof elseContent === 'function' ? elseContent() : elseContent
        );
    }

    /**
     * Make links open in a new window when using ReactMarkdown
     * Use like this:
     * <ReactMarkdown source={text}
     renderers={{
        paragraph: 'span',
        link: UtilsService.reactMarkDownBlankTargets
      }}
     escapeHtml={false} />
     */
    static reactMarkDownBlankTargets(props: { href: string, children: string }): ReactElement {
        return (<a href={props.href} target="_blank" rel="noopener noreferrer">{props.children}</a>);
    }


    /**
     * This is just for diary theme (I think). We may have to move it out of here at some point.
     */
    static getDiaryTheme(): Theme {
        return (createMuiTheme as any)({
            palette: {
                primary: {
                    main: 'rgb(169,17,100)', // #a91164
                    light: 'rgba(211, 137, 177,1)',
                    dark: 'rgba(87, 9, 51,1)'
                },
                warning: {
                    main: '#f0ad4e',
                    light: '#fcf8e3',
                    dark: '#8a6d3b'
                },
                success: {
                    main: '#8bab67',
                    light: '#c3e6cb',
                    dark: '#155724'
                },
                error: {
                    main: '#f44336',
                    light: '#ef9a9a',
                    dark: '#b71c1c'
                },
                info: {
                    main: '#2196f3',
                    light: '#61ade8',
                    dark: '#4669ac' // this is the standby list dark blue color (also hardcoded in css)
                }
            },
            overrides: {
                MuiScopedCssBaseline: {
                    root: {
                        backgroundColor: '#ffffff',
                    },
                },
                MuiCssBaseline: {
                    '@global': {
                        body: {
                            backgroundColor: '#ff00ff',
                        },
                    }
                },
            },
            typography: {
                htmlFontSize: 10,
            },
        });
    }

    /**
     * Returns a new object only with property names that exist in the provided `keys` property
     * @param obj
     * @param keys
     */
    static getObjectByKeys(obj: Object, keys: string[]): Object {
        return fromEntries(
            Object.entries(obj)
                .filter(([name, val]) => keys.includes(name))
        );
    }

    static rangeCheck(val: number, max: number, min: number): number {
        if (val < min) {
            val = min;
        } else if(val > max) {
            val = max;
        }
        return val;
    }


    /**
     * Common container for most react components, in one place for convenient updating.
     * @param child - your component
     * @param injectFirst - if true, will place material ui styles first in the HTML, so that React components' custom styles come last and therefore take precedence
     * @param theme - optionally override the default theme
     */
    static containerWrap(child: ReactNode, injectFirst = true, theme = ReactUtilsService.getDiaryTheme()): ReactElement {
        /**
         * @todo: apply StylesProvider 'injectFirst' wrap and refactor existing scss modules to respect the new order of specificity.
         * This will allow scss modules to override material ui styles, but requires some tidying of existing react component styles.
         * severity: high
         */
        return injectFirst
            ? <StylesProvider injectFirst >
                <ThemeProvider theme={theme}>
                    {child}
                </ThemeProvider>
            </StylesProvider>
            : <ThemeProvider theme={theme}>
                {child}
            </ThemeProvider>;
    }

}

// so you can import directly
export const renderIf = ReactUtilsService.renderIf;
