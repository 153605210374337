import { connect } from 'react-redux';
import ExternalLink from 'shared-components/external-link/index';
import { IRootState } from 'app/reducers';
import { AnyAction } from 'redux';
import { IProps, IOwnProps } from 'shared-components/external-link/types';
import { ThunkDispatch } from 'redux-thunk';

const NS = 'ExternalLinkContainer';

const mapStateToProps = ({widget}: IRootState, ownProps: IOwnProps): IProps & IOwnProps => {
  const {accountId, venueId, theme, accent, font, bgColor} = widget.appSettings;

  return {
    type: ownProps.type,
    label: ownProps.label,
    accountId,
    venueId,
    theme,
    accent,
    font,
    bgColor
  };
};


/**
 * Note this has interface that will need to be updated
 */
const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, AnyAction>): any => { //IDispatchFromProps
  return {

  }
};

const ExternalLinkContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ExternalLink as any);

export default ExternalLinkContainer;
