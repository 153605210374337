import { Dispatch } from "redux";
import * as WebFont from 'webfontloader';
import { RouteService } from "app/services/route/route.service";

export namespace WidgetActionsNS {

  // action type
  export enum Type {
    CHANGED_THEME_COLORS = 'CHANGED_THEME_COLORS',
    CHANGED_THEME_STYLE = 'CHANGED_THEME_STYLE',

    // used for 'preview' mode only
    CHANGED_FONT_LOADING = 'CHANGED_FONT_LOADING',
    CHANGED_FONT_LOADED = 'CHANGED_FONT_LOADED',
    CHANGED_FONT_FAILED = 'CHANGED_FONT_FAILED',

    CHANGED_SIZE = 'CHANGED_SIZE',
  }

  
  // thunk action creators
  
  export const changedThemeColors = (params: string) => (dispatch: Dispatch, getState: any) => {
    return new Promise(resolve => {
      dispatch({type: Type.CHANGED_THEME_COLORS, payload: params});
      resolve();
    });
  };

  export const changedThemeStyle = (params: string) => (dispatch: Dispatch) => {
    return new Promise(resolve => {
      dispatch({type: Type.CHANGED_THEME_STYLE, payload: params});
      resolve();
    });
  };

  export const changedFont = (familyName: string) => (dispatch: Dispatch) => {
    return new Promise(resolve => {
      dispatch({type: Type.CHANGED_FONT_LOADING, payload: familyName});

      WebFont.load({
        google: {
          families: [familyName + ':400:latin']
        },
        fontactive: function(_familyName, fvd) {
          dispatch({type: Type.CHANGED_FONT_LOADED, payload: _familyName});
        },
        fontinactive: function(_familyName, fvd) {
          dispatch({type: Type.CHANGED_FONT_FAILED, payload: _familyName});
        }
      });
    });
  };
}