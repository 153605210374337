import {Dispatch} from "redux";
import * as WebFont from 'webfontloader';
import {from, Observable, of} from 'rxjs';
import {cloneDeep} from 'lodash';
import {catchError, first, map, switchMap, tap} from 'rxjs/operators';
import {IAppQueryStringParams, IAppSettings, IVenue} from "app/models";
import {bookingAction, ROUTE_NAMES, routeType} from 'app/services/route/route.types';
import {RouteService} from 'app/services/route/route.service';
import {BookingService} from 'app/services/booking/booking.service';
import {IRootState} from 'app/reducers';
import {IBookingError} from 'app/services/error/error.types';
import {ErrorService} from 'app/services/error/error.service';
import {ClientService} from 'app/services/client/client.service';
import {WidgetActionsNS} from '../widget';
import {IActionGen, loaderType, loadStatus} from "app/types/common.types";
import {IAccountDetails} from "app/services/account/account.types";
import {SetupActionsNS} from './setupActions';
import {IAppLoadedStatus, IVenueLoadedPayload} from "./interfaces";
import {bookingStatusType, IBookingResponseData, IPrivateFunction, ISavedBookingSelectedOptions} from "app/services/booking/booking.types";
import {PaymentService} from "app/services/payment/payment.service";
import {IMenuOptionsResponse, IOwnedVenue} from "app/services/client/client.types";
import {IBookingErrorAction} from "../booking/interfaces";
import {AnalyticsService} from "app/services/analytics/analytics.service";
import {GoogleEventCategory} from "app/services/analytics/analytics.types";
import {IResponse} from 'app/containers/App/types.d';
import {IHasPromoCodeResponseData} from "app/services/payment/payment.types";
import { ISavedBooking } from '../../services/booking/booking.types';
import {BookingOptionsActionsNS} from "app/actions/bookingOptions/bookingOptionsActions";
import {BookingActionsTypes} from "app/actions/booking/bookingActionsTypes";
import {bookingErrorType, servicePaymentType} from "shared-types/index";

const NS = 'SetupActionsNS';

/**
 * Loads account info from query string 'accountid'
 */
export function loadAccountInfo(
  queryStringParams: IAppQueryStringParams,
  appSettings: IAppSettings,
  dispatch: Dispatch,
  getState: () => IRootState
): Observable<IAppLoadedStatus> {

  return ClientService.getAccount(queryStringParams.accountid)
    .pipe(
      first(),
      switchMap(({data}) => {
        const accountDetails: IAccountDetails = data;
        const {type, name} = RouteService.checkInitialRoute(appSettings);

        let routeName: ROUTE_NAMES;
        let bookingResponseData: IBookingResponseData;

        const routeVenueThenApp = (routeName: ROUTE_NAMES, action: string, data: any, _appSettings?: IAppSettings) => {
          if (_appSettings) {
            dispatchVenueLoadSuccess(queryStringParams, _appSettings, accountDetails, dispatch, getState);
          }

          if (data) {
            const {appSettings, activeVenue} = getState().widget;

            RouteService.routeTo(routeName, dispatch, appSettings, activeVenue).then(() => {
              dispatch({type: action, payload: cloneDeep(data)});
              appLoadCompleteSuccess(dispatch);
              const {savedBooking} = getState().widget;
              sendEventAnalytics(type, activeVenue ? activeVenue.name : null, savedBooking);
            });
          }
        }

        /**
         * Loads private function data, then dispatches app load.
         */
        if (type === routeType.privateFunction) {
          return setupPrivateFunction(appSettings, dispatch, getState, (_appSettings, data) => {
            routeVenueThenApp(name, SetupActionsNS.Type.LOADED_PRIVATE_FUNCTION_PAYMENT_SUCCESS, data, _appSettings);
          });
        }

        /**
         * Loads booking management data, then dispatches app load.
         */
        if (type === routeType.manageBooking) {
          return setupManangeBooking(appSettings.action, appSettings.venueId, appSettings.tokenId, dispatch, getState, (
            data: IBookingResponseData, error: bookingErrorType
          ) => {

            if (!error && data) {

              routeVenueThenApp(name, SetupActionsNS.Type.LOADED_BOOKING, data, appSettings);

              if (data.selectedOptions && data.selectedOptions.length) { // if it has booking options, we fetch their details so they can be displayed on the summary
                const activeVenue: IVenue = getState().widget.activeVenue as IVenue;
                const ids: string[] = getBookingOptionIds(data.selectedOptions);

                ClientService.getBookingOptions(ids.join(','), activeVenue.id)
                  .pipe(first())
                  .subscribe((response: IMenuOptionsResponse) => {
                    dispatch({type: BookingOptionsActionsNS.Type.UPDATE_CACHED_MENU_OPTION_DETAILS, payload: { options: response.data, fullRefresh: false }});
                  });
              }

            } else { // if already paid, then show expired error page
              routeVenueThenApp(ROUTE_NAMES.ERROR_PAGE, BookingActionsTypes.BOOKING_ERROR, error || bookingErrorType.UKNOWN_USERPAYMENTERROR, appSettings);
            }
          });
        }

        /**
         * Loads booking data and then routes to payment
         */
        if (type === routeType.bookingPayment) {
          return setupBookingPayment(appSettings.bookingId, appSettings.venueId, dispatch, getState, (data) => {
            routeName = name;

            // needs to convert using getBookingObj because of pre-auth conditions
            const {payment} = BookingService.getBookingObj(data);
            if (!payment || payment.amountPaid && payment.amountPaid > 0) {
              routeName = ROUTE_NAMES.MANAGE_BOOKING;
            }

            bookingResponseData = data;
          })
            .pipe(
              switchMap((appStatus: IAppLoadedStatus) => {
                if (appStatus.status !== loadStatus.failed) {
                  routeVenueThenApp(routeName, SetupActionsNS.Type.LOADED_BOOKING, bookingResponseData, appSettings);
                }
                return of (appStatus);
              })
          )
        }

        /**
         * If none of above conditions are met, re-routes and app loads
         */
        return routeThenAppLoad(name, queryStringParams.date, dispatch, getState(), () => {
          dispatchVenueLoadSuccess(queryStringParams, appSettings, accountDetails, dispatch, getState);

        }).pipe(first());
      })
    );
}


function  getBookingOptionIds(options: ISavedBookingSelectedOptions[]): string[] {
  return options.reduce((acc, opt) => {
    acc.push(opt.id);

    if (opt.extras) {
      acc = acc.concat(opt.extras.map(({id}) => id));
    }

    return acc;
  }, []);
}

function sendEventAnalytics(type: routeType, venueName: string, savedBooking: ISavedBooking): void {
  switch (type) {
    case routeType.privateFunction:
      AnalyticsService.event(GoogleEventCategory.URL, 'Private Function', venueName, {
        serviceName: savedBooking?.serviceName,
        serviceId: savedBooking?.serviceId
      });
      break;

    case routeType.manageBooking:
      AnalyticsService.event(GoogleEventCategory.URL, 'Event', venueName, {
        serviceName: savedBooking?.serviceName,
        serviceId: savedBooking?.serviceId
      });
      break;
  }
}

function checkHasPromoCode(
  bookingId: string, venueId: number,
  dispatch: Dispatch, getState: () => IRootState
): Observable<IAppLoadedStatus> {

  return PaymentService.hasPromoCode(
    bookingId,
    venueId)
    .pipe(
      first(),
      map((response: IResponse<IHasPromoCodeResponseData>) => {
        const data: IHasPromoCodeResponseData = (response as IResponse<IHasPromoCodeResponseData>).data;

        if (data) {
          dispatch({type: BookingActionsTypes.UPDATE_HAS_PROMOTION_CODE, payload: data.showPromoCode });
          return {
            status: loadStatus.success,
            completeLoadStatus: true
          }
        }

        console.error(NS, 'PromoCode verification error');
        return {
          msg: 'Promo Code not verified',
          status: loadStatus.failed
        }
      })
    )
}


export function setupBookingPayment(
  bookingId: string, venueId: number,
  dispatch: Dispatch, getState: () => IRootState, venueLoadedCB: (data: IBookingResponseData) => void
): Observable<IAppLoadedStatus> {

  dispatch({type: SetupActionsNS.Type.APP_LOAD_PROGRESS, payload: {
    appLoadMessage: 'Retrieving your booking',
    appLoaderType: loaderType.hideContent
  } as SetupActionsNS.IAppLoading});

  return ClientService.getBookingById(bookingId, venueId)
    .pipe(
      first(),
      catchError(err => {
        return of(err.response.status === 404 || err.response.status === 500 ? bookingErrorType.badRequest : bookingErrorType.linkExpired);
      }),
      switchMap((response: IResponse<IBookingResponseData> | bookingErrorType) => {

        const data: IBookingResponseData = (response as IResponse<IBookingResponseData>).data;
        venueLoadedCB(data || null);

        // Case booking has been canceled or moved - payment link is outdated
        if (data.status.statusType === bookingStatusType.cancelled) {
          return of({
            msg: 'Payment Link expired',
            status: loadStatus.failed,
            completeLoadStatus: false
          })
        }

        // Just in case payment requirement gets removed
        if (data.paymentPending.paymentType === servicePaymentType.noPayment) {
          return of({
            msg: 'Payment not required',
            status: loadStatus.failed
          });
        }

        // only checks for promocode if booking has PendingPayment status
        if (data) {
          if (data.status.statusType == bookingStatusType.pendingPayment) {
            return checkHasPromoCode(bookingId, venueId, dispatch, getState);
          }
          else {
            return of({
              msg: '',
              status: loadStatus.success,
              completeLoadStatus: true
            })
          }
        }

        const state: IRootState = getState();
        const bookingError: IBookingError = ErrorService.getBookingErrorFromType(response as bookingErrorType, state.widget.activeVenue as IVenue);
        return of({
          msg: bookingError.message,
          status: loadStatus.failed
        })
      })
    )
}

function setupManangeBooking(
  action: bookingAction, venueId: number, bookingTokenId: string,
  dispatch: Dispatch, getState: () => IRootState, venueLoadedCB: (data: IBookingResponseData, error?: bookingErrorType) => void
): Observable<IAppLoadedStatus> {

  // action = (action === bookingAction.confirm || action === bookingAction.manageBooking) ? action : bookingAction.edit;

  dispatch({type: SetupActionsNS.Type.APP_LOAD_PROGRESS, payload: {
    appLoadMessage: 'Retrieving your booking',
    appLoaderType: loaderType.hideContent
  } as SetupActionsNS.IAppLoading});

  return ClientService.getBooking(bookingTokenId, venueId)
    .pipe(
      first(),
      catchError(err => {
        return of(err.response.status === 500 ? bookingErrorType.badRequest : bookingErrorType.linkExpired); // if the token has expired, then the request will 404
      }),
      map((response: IResponse<IBookingResponseData> | bookingErrorType) => {
        console.log(NS, 'response', response);

        const state: IRootState = getState();
        const data: IBookingResponseData = (response as IResponse<IBookingResponseData>).data;
        venueLoadedCB(data || null, !data ? response as bookingErrorType : null);
        if (data) {
          return {
            status: loadStatus.success
          }
        }
        const bookingError: IBookingError = ErrorService.getBookingErrorFromType(response as bookingErrorType, state.widget.activeVenue as IVenue);
        return {
          msg: bookingError.message,
          status: loadStatus.failed
        }
      })
    )
}


function dispatchVenueLoadSuccess(
  queryStringParams: IAppQueryStringParams, appSettings: IAppSettings, accountDetails: IAccountDetails, dispatch: Dispatch, getState: () => IRootState
): void {

  const ownedVenue: IOwnedVenue = accountDetails.ownedVenues.find(o => o.id === appSettings.venueId);

  dispatch({type: SetupActionsNS.Type.VENUE_LOAD_SUCCESS, payload: {
      queryStringParams, appSettings, accountDetails, overrideStyleGuide: false
    } as IVenueLoadedPayload})


  const disallowOnlineBookings: boolean = !ownedVenue || !ownedVenue.active || !ownedVenue.widgetSettings || !ownedVenue.widgetSettings.allowOnlineBookings;
  if (appSettings.venueId && disallowOnlineBookings) {
    RouteService.routeTo(ROUTE_NAMES.ERROR_PAGE, dispatch, appSettings, ownedVenue).then(() => {
      dispatch({type: BookingActionsTypes.BOOKING_ERROR, payload: bookingErrorType.onlineBookingsOffMessage} as IBookingErrorAction);
    });
  } else {
    /**
     * Initializes analytics service.
     * Must come after VENUE_LOAD_SUCCESS dispatch so that `activeVenue` is set.
     * If on the `venues` page, we don't want to pass the `activeVenue`, as it is the minimal version, which doesn't have a selected venue `id yet.
     */
    const activeVenue: IVenue = getState().widget.activeVenue as IVenue;
    if (activeVenue.id) {
      AnalyticsService.init(activeVenue as IVenue);
    }
  }
}


function setupPrivateFunction(
  appSettings: IAppSettings,
  dispatch: Dispatch,
  getState: () => IRootState,
  venueLoadedCB: (appSettings: IAppSettings, data: IPrivateFunction) => void
): Observable<IAppLoadedStatus> {

  const _appSettings: IAppSettings = {
    ...appSettings
  }
  _appSettings.eventId = BookingService.getFirstFromCommaSepartedString(appSettings.serviceids);
  _appSettings.privateFunction = true;

  return loadPrivateFunctionPayment(_appSettings.eventId, _appSettings.venueId, dispatch)
    .pipe(
      map(({error, data}) => {
        // loads venue info early so we can access `widget.activeVenue` below
        venueLoadedCB(_appSettings, null);

        const {widget}: IRootState = getState();

        const isPaymentAction: boolean = _appSettings.action === bookingAction.payment || _appSettings.action === bookingAction.payments;
        const paymentNotSetup: boolean = isPaymentAction && !widget.activeVenue.paymentSettings;
        if (paymentNotSetup) {
          error = bookingErrorType.paymentNotSetup;
        }

        // must come after VENUE_LOAD_SUCCESS because otherwise `widget.activeVenue` isn't set
        const bookingError: IBookingError = ErrorService.getBookingErrors().find(o => o.name === error);
        if (bookingError) {
          const bookingErrorRendered: IBookingError = ErrorService.getBookingErrorFromType(bookingError.name, widget.activeVenue as IVenue);
          error = bookingErrorRendered.message;
        }

        venueLoadedCB(null, error ? null : data);

        return {status: error ? loadStatus.failed : loadStatus.success, msg: error};
      })
    )
}

/**
 * Loads font from query string, or uses Roboto if none specified
 * Font names must be title case for `WebFont` library, but query string should be in
 * param case (eg 'droid-sans').
 */
export function loadFont(queryStringParams: IAppQueryStringParams, dispatch: Dispatch): Observable<IAppLoadedStatus> {

  const familyName: string = queryStringParams.font ? decodeURIComponent(queryStringParams.font) : 'Roboto';

  dispatch({type: WidgetActionsNS.Type.CHANGED_FONT_LOADING, payload: familyName});

  return new Observable(observer => {

    WebFont.load({
      google: {
        families: [`${familyName || 'Roboto'}:400:latin`]
      },
      fontactive: function(_familyName, fvd) {
        dispatch({type: WidgetActionsNS.Type.CHANGED_FONT_LOADED, payload: _familyName});
        observer.next({
          status: loadStatus.success
        });
        observer.complete();
      },
      fontinactive: function(_familyName, fvd) {
        dispatch({type: WidgetActionsNS.Type.CHANGED_FONT_FAILED, payload: _familyName});
        observer.next({
          msg: `Font failed to load '${_familyName}'.`,
          status: loadStatus.failed
        });
        observer.complete();
      }
    });
  });
}

export function showProcessingOverlayLoader(dispatch: Dispatch, appLoadMessage = `Processing, please don't refresh your browser.`): void {
  dispatch({type: SetupActionsNS.Type.APP_LOAD_PROGRESS, payload: {
    appLoadMessage,
    appLoaderType: loaderType.overlayContent
  }} as IActionGen<SetupActionsNS.IAppLoading>);
}

function routeThenAppLoad(
  routeName: ROUTE_NAMES,
  date: string,
  dispatch: Dispatch,
  state: IRootState,
  venueLoadedCB: () => void
): Observable<IAppLoadedStatus> {

  // if date is included in the query string we need to show the loader for a bit longer until schedule is received
  let completeLoadStatus = !date;
  if (routeName !== ROUTE_NAMES.SITTING) {
    // but if not showing the calender, then we don't need the schedule, so can just mark as complete
    completeLoadStatus = true;
  }

  return from(RouteService.routeTo(routeName, dispatch, state.widget.appSettings, null))
    .pipe(
      first(),
      tap(() => venueLoadedCB()),
      map(() => ({status: loadStatus.success, completeLoadStatus})),
      catchError(err => of({status: loadStatus.failed, msg: err}))
    );
}

export function appLoadCompleteSuccess(dispatch: Dispatch): void {
  dispatch({type: SetupActionsNS.Type.APP_LOAD_COMPLETE, payload: {
    completeLoadStatus: true,
    status: loadStatus.success
  }} as SetupActionsNS.IAppLoadComplete);
}


function loadPrivateFunctionPayment(functionId: string, venueId: number, dispatch: Dispatch): Observable<{error?: string, data?: IPrivateFunction}> {

  return PaymentService.getFunctionPayment(functionId, venueId)
    .pipe(
      first(),
      switchMap((data: IPrivateFunction) => {
        if (data.isPaid) {
          return of({
            error: bookingErrorType.duplicateFunctionPayment
          });
        } else {
          return of({data});
        }
      }),
      catchError(err => of({error: err.message}))
    )
}
