import {AnalyticsService} from "app/services/analytics/analytics.service";
import {GoogleEventCategory} from "app/services/analytics/analytics.types";
import {IVenueMinimal, IWidgetModel} from "app/models";
import {IBooking, IBookingMenuOption} from '../../services/booking/booking.types';
import {
  servicePaymentType,
  ISectionOrder,
  IScheduleService
} from "shared-types/index";
import { GTMFields } from './interfaces';

const NS = 'AnalyticsHelpers';

/**
 * Useful for GA/GTM filtering regular services from events. Do not change these values as they may be used by venues'
 * GTM or GA accounts.
 * @param activeService
 */
export const getServiceType = (activeService: IScheduleService): string => {
  return activeService?.id.indexOf('event_') === 0 ? 'event' : 'service'; // don't change values!
}

const addServicePrice = (data: any, activeService: IScheduleService): void => {
  if (activeService?.paymentDetails?.paymentType
    && activeService?.paymentDetails?.paymentType !== servicePaymentType.noPayment
  ) {
    data.servicePrice = activeService.paymentDetails.price;
  }
}

const addGTMFields = (data: any, booking: IBooking, gtmFields: string[] = []) => {
  const isEmailIncluded = gtmFields?.includes(GTMFields.Email);
  const isPhoneIncluded = gtmFields?.includes(GTMFields.Phone);
  const isFirstNameIncluded = gtmFields?.includes(GTMFields.FirstName);
  const isLastNameIncluded = gtmFields?.includes(GTMFields.LastName);

  if (isEmailIncluded) {
    data.email = booking.customer.email;
  }

  if (isPhoneIncluded) {
    data.phone = booking.customer.phone;
  }

  if (isFirstNameIncluded) {
    data.firstName = booking.customer.firstName;
  }

  if (isLastNameIncluded) {
    data.lastName = booking.customer.lastName;
  }

  if (isEmailIncluded || isPhoneIncluded ||isFirstNameIncluded || isLastNameIncluded) {
    data.subscribed = booking.customer.subscribed;
    data.bookingDate = booking.viewDate;
  }
}

export function sendPaymentAnalytics( {appSettings, activeVenue, booking, activeService, activeSection}: IWidgetModel ): void {
  const data: any = {
    serviceName: activeService?.name,
    serviceId: activeService?.id,
    serviceType: getServiceType(activeService),
    covers: booking?.covers,
    sectionId: activeSection?.id,
    sectionName: activeSection?.name
  };
  addServicePrice(data, activeService);
  addGTMFields(data, booking, activeVenue.analytics?.googleGTMFields);
  AnalyticsService.event(
    GoogleEventCategory.Payment,
    appSettings.privateFunction ? 'Private Function Paid' : 'Booking Paid',
    activeVenue ? activeVenue.name : null, data
  );
}

export function sendBookingAnalytics( activeVenue: IVenueMinimal, booking: IBooking, activeService: IScheduleService, activeSection: ISectionOrder ): void {
  const data: any = {
    serviceName: activeService?.name,
    serviceId: activeService?.id,
    serviceType: getServiceType(activeService),
    covers: booking?.covers,
    sectionId: activeSection?.id,
    sectionName: activeSection?.name
  };
  addServicePrice(data, activeService);
  addGTMFields(data, booking, activeVenue.analytics?.googleGTMFields);
  AnalyticsService.event(
    GoogleEventCategory.Booking,
    'Booking Confirmed',
    activeVenue ? activeVenue.name : null, data
  );
}

export function sendOnlineBookingAnalytics( activeVenue: IVenueMinimal, booking: IBooking, activeService: IScheduleService, activeSection: ISectionOrder ): void {
  const data: any = {
    serviceName: activeService?.name,
    serviceId: activeService?.id,
    serviceType: getServiceType(activeService),
    covers: booking?.covers,
    sectionId: activeSection?.id,
    sectionName: activeSection?.name
  };
  addServicePrice(data, activeService);
  addGTMFields(data, booking, activeVenue.analytics?.googleGTMFields);
  AnalyticsService.event(
    GoogleEventCategory.ManageBooking,
    'Booking Confirmed Online',
    activeVenue ? activeVenue.name : null, data
  );
}

export function sendEditedOnlineBookingAnalytics( activeVenue: IVenueMinimal ): void {
  AnalyticsService.event(
    GoogleEventCategory.ManageBooking,
    'Booking Edited Online',
    activeVenue ? activeVenue.name : null
  );
}

export function sendCancelledOnlineBookingAnalytics( activeVenue: IVenueMinimal ): void {
  AnalyticsService.event(
    GoogleEventCategory.ManageBooking,
    'Booking Cancelled Online',
    activeVenue ? activeVenue.name : null
  );
}

export function sendCoversAnalytics(activeVenue: IVenueMinimal, coversCount: number): void {
  AnalyticsService.event(
    GoogleEventCategory.UserInput,
    'Covers updated',
    activeVenue ? activeVenue.name : null,
    {coversCount}
  );
}

export function sendDateAnalytics(activeVenue: IVenueMinimal, dateStr: string): void {
  AnalyticsService.event(
    GoogleEventCategory.UserInput,
    'Date selected',
    activeVenue ? activeVenue.name : null, {
      date: dateStr
    }
  );
}

export function sendTimeAnalytics(activeVenue: IVenueMinimal, viewTime: string, activeService: IScheduleService): void {
  AnalyticsService.event(
    GoogleEventCategory.UserInput,
    'Time selected',
    activeVenue ? activeVenue.name : null, {
      time: viewTime,
      serviceName: activeService?.name,
      serviceId: activeService?.id,
      serviceType: getServiceType(activeService)
    }
  );
}

export function sendErrorSavingAnalytics(activeVenue: IVenueMinimal): void {
  AnalyticsService.event(
    GoogleEventCategory.UserInput,
    'Error saving',
    activeVenue ? activeVenue.name : null
  );
}


export function sendTimeExpiredAnalytics(activeVenue: IVenueMinimal): void {
  AnalyticsService.event(
    GoogleEventCategory.UserInput,
    'Time expired',
    activeVenue ? activeVenue.name : null
  );
}

export function sendBookingOptionAnalytics(activeVenue: IVenueMinimal, bookingOptions: IBookingMenuOption[]): void {
  AnalyticsService.event(
    GoogleEventCategory.UserInput,
    'Booking option selected',
    activeVenue ? activeVenue.name : null, {
      bookingOptions
    }
  );
}

export function sendSessionTimeoutAnalytics(activeVenue: IVenueMinimal): void {
  AnalyticsService.event(
    GoogleEventCategory.Timeout,
    'Payment Timeout',
    activeVenue ? activeVenue.name : null
  );
}

export function sendBookingDeletedAnalytics(activeVenue: IVenueMinimal): void {
  AnalyticsService.pageView(
    'paymentBookingCancelled',
    'PaymentBooking Cancelled', // page name
    activeVenue ? activeVenue.name : null
  );
}

export function sendConfirmPaymentDetailsAnalytics(activeVenue: IVenueMinimal): void {
  AnalyticsService.pageView(
    'confirmPaymentDetails',
    'Confirm Payment Details', // page name
    activeVenue ? activeVenue.name : null
  );
}

