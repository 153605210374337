import React, { ReactElement } from 'react';
import {from, Observable} from "rxjs";
import axios, {AxiosAdapter, AxiosInstance, AxiosRequestConfig} from 'axios';
import {IResponse} from 'app/containers/App/types';
import {setupCache} from 'axios-cache-adapter';
import appValues from "app/constants/appValues";
import {
  IHasPromoCodeResponseData,
  IPromoCodeResponse,
} from "./payment.types";
import {
  bookingStatusType,
  IBooking,
  IBookingMenuOption,
  IBookingPayment,
  IPrivateFunction
} from "../booking/booking.types";
import {map} from "rxjs/operators";
import {IGroupedTablesBoxGroup, IGroupedTablesBoxItem} from "app/components/GroupedTablesBox/types";
import {IntlService} from "../intl/intlService";
import {sortBy} from 'lodash';
import { noCase } from 'change-case';
import ExternalLinkContainer from 'app/components/ExternalLink/container';
import { externalLinkType } from 'shared-components/external-link/types';
import {IPaymentSummaryMenuOption} from "app/components/PaymentSummary/types";
import {
  IPaymentDetailsGenericData,
  IServicePaymentOption,
  ISimpleBookingOption,
  servicePaymentType
} from "shared-types/index";

const NS = 'PaymentService';

// @toDo: check cache time required. severity: high
const cache = setupCache({
  maxAge: 24 * 60 * 60 * 1000 // cached for 1 day
});

// Create `axios` instance passing the newly created `cache.adapter`
const api: AxiosInstance = axios.create({
  adapter: cache.adapter as AxiosAdapter
});

/**
 * Should only get called once per session/app load.
 * Used for better SEQ logging.
 * Same thing also in ClientService.
 */
const requestInterceptor = (config: AxiosRequestConfig) => {
  config.headers['X-NBI-CorrelationId'] = appValues.GLOBAL_SESSION_CORRELATION_ID;
  config.headers['X-NBI-Source'] = 'widget2';
  return config;
};
api.interceptors.request.use(requestInterceptor);

export class PaymentService {

  static PAYMENT_SUMMARY_URL = `${appValues.APIBASE}/bookings/payments/completed`;
  static PAY_NOW_URL = `${appValues.APIBASE}/bookings/payments/paynow`;
  static PRE_AUTH_URL = `${appValues.APIBASE}/bookings/payments/preauth`;
  static SETUP_PRE_AUTH_STRIPE3D_URL = `${appValues.APIBASE}/bookings/payments/stripe-3d-preauth/setup`;
  static FINALIZE_PRE_AUTH_STRIPE3D_URL = `${appValues.APIBASE}/bookings/payments/stripe-3d-preauth/finalize`;
  static PROMO_CODE_URL = `${appValues.APIBASE}/bookings/payments/apply-promotion-code`;
  static HAS_PROMO_CODE_URL = `${appValues.APIBASE}/bookings/payments/has-promotion-code`;



  static getAxiosInstance(): AxiosInstance {
    return api;
  }

  static isAmex(cardNumber: string): boolean {
    return cardNumber[0] === '3';
  }

  /**
   * Rejects string with anything but numbers
   */
  static validateNumbersOnly(val: string): boolean {
    return !/^[0-9]*$/.test(val);
  }

  /**
   * Adds errors for CVC, talking into account different rules for AMEX
   */
  static addCVCValidationErrors(amexCardEntered: boolean, cvc: string, errors: any): void {
    const amexCVCLength = 4;
    const otherCVCLength = 3;

    if (!cvc) {
      errors.cvc = 'You forgot to enter your CVC number';
    } else if (this.validateNumbersOnly(cvc)) {
      errors.cvc = 'Please only enter digits';
    } else {
      if (cvc.length < (amexCardEntered ? amexCVCLength : otherCVCLength)) {
        errors.cvc = 'Your number is too short';
      } else if (cvc.length > (amexCardEntered ? amexCVCLength : otherCVCLength)) {
        errors.cvc = 'Your number is too long';
      }
    }
  }

  /**
   * Adds errors for card number, talking into account different rules for AMEX
   */
  static addCardNumberValidationErrors(amexCardEntered: boolean, cardNumber: string, errors: any): void {
    const amexCardNumberLength = 15;
    const otherCardNumberLength = 16;

    if (!cardNumber) {
      errors.cardNumber = 'You forgot to enter your card number!!';
    } else if (this.validateNumbersOnly(cardNumber)) {
      errors.cardNumber = 'Please only enter digits';
    } else {
      if (cardNumber.length < (amexCardEntered ? amexCardNumberLength : otherCardNumberLength)) {
        errors.cardNumber = 'Your number is too short';
      } else if (cardNumber.length > (amexCardEntered ? amexCardNumberLength : otherCardNumberLength)) {
        errors.cardNumber = 'Your number is too long';
      }
    }
  }
 
  static beginStandbyPayment(bookingId: string, venueId: number): Observable<IResponse<string>> {
    return from(
      api.get(`${appValues.APIBASE}/bookings/venues/${venueId}/begin-standby-payment/${bookingId}`)
    );
  }

  static getFunctionPayment(id: string, venueId: number): Observable<IPrivateFunction> {
    return from(
      api.get(appValues.APIBASE + '/bookings/venues/' + venueId.toString() + '/private-functions/' + id)
    ).pipe(
      map(({data}: any) => data as IPrivateFunction),
    );
  }

  static applyPromoCode(promotionCode: string, bookingId: string, venueId: number): Observable<IPromoCodeResponse> {
    const params = {
      venueId,
      bookingId,
      promotionCode
    }
    return from(
      api.post(this.PROMO_CODE_URL, {...params})
    );
  }

  static hasPromoCode(bookingId: string, venueId: number): Observable<IResponse<IHasPromoCodeResponseData>> {
    const params = {
      venueId,
      bookingId
    }
    return from(
      api.post(this.HAS_PROMO_CODE_URL, {...params})
    );
  }


  private static formatCC(cardNumber: string): string {

    //if the card number is already formatted, just return as is
    if (cardNumber.indexOf(' ') !== -1 && cardNumber.indexOf('X') !== -1) {
      return cardNumber;
    }

    const maskedNumber: string = cardNumber.split('')
      .map((ch: string, i: number) => i < 12 ? 'X' : ch).join('');

    const spacedNumber: string = maskedNumber.split('')
      .map((ch: string, i: number) => i > 0 && i % 4 === 0 ? ` ${ch}` : ch).join('');

    return spacedNumber;
  }


  static getPaymentDetailGroup(payment: IBookingPayment, paymentDetails: IPaymentDetailsGenericData, currency: string): IGroupedTablesBoxGroup {

    if (!paymentDetails) {
      return {
        heading: 'Payment Details Error',
        items: []
      }
    }

    const items: IGroupedTablesBoxItem[] = [
      {order: 0, name: `Cardholder Name`, value: paymentDetails.name},
      {order: 1, name: `Credit Card Number`, value: PaymentService.formatCC(paymentDetails.cardNumber)},
      {
        order: 10, name: `Total Amount`, prefix: `(${currency})`, value: (
          <span className="secondary-text">
          {IntlService.currencyValue(payment.amountDue, currency)}
        </span>
        )
      }
    ];

    if (payment.promotionCode) {
      items.push(
        {order: 2, name: `Promotion Code`, value: payment.promotionCode}
      )
    }

    if (payment.discountAmount) {
      items.push(
        {
          order: 3, name: `Required Payment`, prefix: `(${currency})`, value: (
            <span className="secondary-text">
            {IntlService.currencyValue(payment.price, currency)}
          </span>
          )
        },
        {
          order: 4, name: `Discount Amount`, prefix: `(${currency})`, value: (
            <span className="secondary-text">
            {IntlService.currencyValue(payment.discountAmount, currency)}
          </span>
          )
        }
      )
    }

    return {
      heading: 'Payment Details',
      items: sortBy(items, 'order')
    }
  }

  /**
   * Determines if booking is in Payment Pending state.
   * @param booking - you can use IBooking or a subset with props below
   */
  static isPaymentPending(status: bookingStatusType, payment: IBookingPayment): boolean {
    if (status === bookingStatusType.pendingPayment ||
      payment && (
        // there is a bug where a service can still have price when paymentType is noPayment, so must check both
        payment.price > 0 && payment.paymentType !== servicePaymentType.noPayment
      ) && (
        !payment.amountPaid ||
        payment.amountPaid < payment.price
      )) {
      return true;
    }
    return false;
  }

  static checkForUnconfirmedAndPaid(booking: IBooking): boolean {
    /**
     * 'isPaid' here checks if the booking has been fully paid for. This can include a pre-auth that the customer
     * has confirmed via the payment gateway - however, if the pre-auth has been released, then it is no longer considered
     * paid.
     */
    const isPaid = booking.payment
      && booking.payment.price > 0
      && booking.payment.amountPaid
      && booking.payment.amountPaid >= booking.payment.price;

    if (booking.status === bookingStatusType.unconfirmed && isPaid) {
      return true;
    }
    return false;
  }

  static getPaymentMessage(paymentType: servicePaymentType, currency: string, price: number, priceIsPerPerson = true, bgColor?: string): ReactElement {

    const isPreAuth = paymentType === servicePaymentType.preAuth;
    return <>A
      {!isPreAuth ? <> {noCase(paymentType)} </> : null}
      {isPreAuth ? <> credit card Booking Guarantee </> : null}
      <>of </>
      <span className="secondary-text">
        {IntlService.currencyValue(price, currency)}
      </span>
      &nbsp;{priceIsPerPerson ? 'per person' : '' } is required.
      {isPreAuth ?
        <> Funds will be verified, but not charged to your card at this time.&nbsp;
        <ExternalLinkContainer label={'View Booking Guarantee Policy.'} type={externalLinkType.preAuth} bgColor={bgColor} />
        </>
        : null
      }</>
  }

  static getBOForPaymentSummary(bookingOptions: IServicePaymentOption[], selectedMenuOptions: IBookingMenuOption[], cachedMenuOptionDetails: ISimpleBookingOption[]): IPaymentSummaryMenuOption[] {
    if (!bookingOptions) {
      return [];
    }

    return bookingOptions
      .reduce((acc, {id, label, price}) => {
        const selectedMenuOption: IBookingMenuOption = selectedMenuOptions.find(({menuOptionId}) => menuOptionId === id);

        if (selectedMenuOption && selectedMenuOption.quantity) {
          const {extras, quantity} = selectedMenuOption;
          const childOpts: IBookingMenuOption[] = [];
          if (extras) {
            if (extras.explicitChildMenuOptions) {
              extras.explicitChildMenuOptions.forEach(opts => {
                opts.forEach(o => childOpts.push(o));
              });
            }
            if (extras.implicitChildMenuOptions) {
              extras.implicitChildMenuOptions.forEach(o => childOpts.push(o));
            }
          }

          const childLineItems: IPaymentSummaryMenuOption[] = childOpts.length ? childOpts.map(({menuOptionId, quantity}) => {
            const details = cachedMenuOptionDetails.find(o => o.id === menuOptionId);
            return details && quantity ? {
              quantity,
              label: details.label,
              price: details.price
            } : null;
          }) : null;

          acc.push({label, price, childLineItems, quantity: selectedMenuOption.quantity});
        }

        return acc;
      }, []);
  }

  static getStandbyPaidNoTableMessage(phone: string, currency?: string, amountPaid = 0, isPreAuth = false): string {
    return `This booking is on the standby list and does not have an allocated table${
      amountPaid > 0
        ? `, but you have ${ isPreAuth ? 'pre-authorised' : 'made' } a payment of ${IntlService.currencyValue(amountPaid, currency)}. Please check the status of your booking with the venue on ${phone}.`
        : `. Please confirm with the venue before making payment on ${phone}.`
    }`;
  }
}
