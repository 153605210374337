import { Dispatch } from "redux";
import { UIRouterReact, pushStateLocationPlugin, servicesPlugin, Transition } from '@uirouter/react';
import {
  ROUTE_NAMES,
  STEP_ROUTES,
  bookingAction,
  IInitialRoute,
  routeType,
  IRegTransition,
  IRouteState, IRouteInfo
} from './route.types';
import { modeType, IAppSettings } from '../../models';
import { NavigationActionsNS } from 'app/actions/navigation/navigationActions';
import { INavigation } from 'app/reducers/state';
import { BookingService } from "../booking/booking.service";
import { AnalyticsService } from "../analytics/analytics.service";
import {
  CUSTOMER_DETAILS_INFO,
  ERROR_PAGE_INFO,
  MANAGE_BOOKING_INFO,
  PAYMENT_COMPLETE_INFO,
  PAYMENTS_INFO,
  SETUP_INFO,
  SITTING_INFO,
  SUMMARY_INFO,
  THANK_YOU_INFO,
  VENUES_INFO
} from "app/services/route/route.info";

const NS = 'RouteService';


export const verifyActionAsEnum = (action: string): bookingAction => {
  for (const prop in bookingAction) {
    if (prop === action) {
      return (bookingAction as any)[prop];
    }
  }
  // always default to edit if action is invalid
  return bookingAction.edit;
}


export const routeInfo: IRouteInfo[] = [
  SETUP_INFO, SITTING_INFO, VENUES_INFO, CUSTOMER_DETAILS_INFO, SUMMARY_INFO,
  THANK_YOU_INFO, ERROR_PAGE_INFO, MANAGE_BOOKING_INFO, PAYMENTS_INFO, PAYMENT_COMPLETE_INFO
];

// exported as a singleton instance to keep referfence to 'router' object
class RouteService {

  private router: UIRouterReact;

  constructor() {
    this.router = new UIRouterReact();
    this.router.plugin(servicesPlugin);
    this.router.plugin(pushStateLocationPlugin);
  }

  getRouter(): UIRouterReact {
    return this.router;
  }

  /**
   * State-aware routing (call from action creator).
   */
  routeTo(_routeName: ROUTE_NAMES, dispatch: Dispatch, appSettings: IAppSettings, activeVenue?: {name: string}): Promise<any> { // TransitionPromise |
    // this will check route rules
    const routeName: ROUTE_NAMES = this.checkRouteRules(appSettings, _routeName);

    // this will keep track of the current route in the store
    dispatch({type: NavigationActionsNS.Type.CHANGED_ROUTE_BY_NAME, payload: routeName});

    if (activeVenue) {
      const {analyticsUrl, analyticsTitle} = this.getRouteInfo(routeName);
      AnalyticsService.pageView(analyticsUrl, analyticsTitle, activeVenue.name);
    }

    if (routeName) {
      return this.router.stateService.go(routeName);
      // .then((routeData: StateObject) => {
      //   console.log(NS, 'routeData', routeData)
      // });
    } else {
      return Promise.resolve(null);
    }
  }

  registerTransitions({transitionStart, transitionEnd, transitionFail}: IRegTransition): void {

    if (transitionStart) {
      this.router.transitionService.onBefore({}, (transition: Transition) => {
        // Start transition
        // console.log(NS, 'Start transition', transition)
        transitionStart(transition.from(), transition.to(), transition.$id);
      });
    }

    if (transitionEnd) {
      this.router.transitionService.onSuccess({}, (transition: Transition) => {
        // End transition
        // console.log(NS, 'End transition', transition)
        transitionEnd(transition.from(), transition.to(), transition.$id);
      });
    }

    if (transitionFail) {
      this.router.transitionService.onError({}, (transition: Transition) => {
        // fail transition
        // console.log(NS, 'fail transition', transition)
        transitionFail(transition.from(), transition.to(), transition.$id);
      });
    }
  }

  /**
   * returns if the current route is a 'step' route, meaning a route reachable by next/prev buttons.
   * Does not take into account the fact that Venues gets removed from navigation store though.
   */
  isCurrentRouteInSteps(): boolean {
    const currentRouteName: string = this.router.stateService.current.name;
    return STEP_ROUTES.some(r => r === currentRouteName);
  }

  getNextRouteInSteps(state: INavigation): number {
    const lastIndex = state.stepRoutes.length - 1;
    let nextIndex = state.currentStepIndex + 1;
    if (nextIndex >= lastIndex) {
      nextIndex = lastIndex;
    }
    return nextIndex;
  }

  getPrevRouteInSteps(state: INavigation): number {
    let prevIndex = state.currentStepIndex - 1;
    if (prevIndex < 0) {
      prevIndex = 0;
    }
    return prevIndex;
  }

  /**
   * Manages routes based on app state.
   * Returns a promise.
   * If you depend on updates to app's store in your new route, it must come after the promise has resolved
   */
  checkRouteRules(appSettings: IAppSettings, nextRouteName: ROUTE_NAMES): ROUTE_NAMES {
    // console.log(NS, 'nextRouteName', nextRouteName)

    if (appSettings.mode === modeType.preview) {
      return ROUTE_NAMES.SITTING;
    }

    // all other route changes
    return nextRouteName;
  }

  checkInitialRoute(appSettings: IAppSettings): IInitialRoute {
    if (appSettings.tokenId) {
      return this.setupManageBooking(appSettings);
    }

    if (appSettings.bookingId) {
      return {
        name: ROUTE_NAMES.PAYMENTS,
        type: routeType.bookingPayment
      }
    }

    if (BookingService.isPrivateFuncEvent(appSettings)) {
      return {
        name: ROUTE_NAMES.PAYMENTS,
        type: routeType.privateFunction
      };
    }
    return this.goToStart(appSettings);
  }


  private setupManageBooking(appSettings: IAppSettings): IInitialRoute {

    const {payment, payments} = bookingAction;

    /**
     * If action === payment/s and query string includes a service id, then this would mean it is a private function
     * payment. But a private function payment wouldn't have a `tokenId`, so if this happens it is invalid and should
     * direct back to the standard start page.
     */
    if (appSettings.serviceids && (appSettings.action !== payment && appSettings.action !== payments)) {
      return this.goToStart(appSettings);
    }

    return {
      name: ROUTE_NAMES.MANAGE_BOOKING,
      type: routeType.manageBooking
    };
  }

  private goToStart(appSettings: IAppSettings): IInitialRoute {
    if (appSettings.venueId) {
      return {
        name: ROUTE_NAMES.SITTING
      };
    }
    return {
      name: ROUTE_NAMES.VENUES
    };
  }

  private getRouteInfo(routeName: ROUTE_NAMES): IRouteInfo {
    return routeInfo.find(s => s.name === routeName);
  }

}

// creates a singleton instance to export
const instance = new RouteService();
// @ts-ignore
export { instance as RouteService };
// @ts-ignore
